import React from 'react';
import { getChildrenToRender } from './utils';

class Content1314 extends React.PureComponent {
  render() {
    const { ...props } = this.props;
    const { dataSource } = props;
    delete props.dataSource;
    delete props.isMobile;
    return (
      <div {...props} {...dataSource.OverPack}>
          {dataSource.titleWrapper.children.map(getChildrenToRender)}
      </div>
    );
  }
}

export default Content1314;

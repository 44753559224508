import React from 'react';
import axios from 'axios';
import { Tabs, Row, Col } from 'antd';
import HotItem from './HotItem';
import moment from 'moment';
import { Link } from 'react-router-dom';
const { TabPane } = Tabs;

const tabstyle = {
  textAlign: 'left',
};

const tabstyle2 = {
  textAlign: 'left',
  backgroundColor: '#F7F7F7'
};

const params = [
  'notice',
  'publish',
  'groupDynamics',
  'policy',
  'recruit',
];

const newsBlock = [
  '通知公告',
  '结果公示',
  '集团动态',
  '政策资讯',
  '人才招聘'
];

export default class NewsItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      menukey: 0,
    }
  }

  componentDidMount() {
    axios.get(`/hr/api/v1/newsTypes/selectNewsType`, {
      params: {
        groupCode: params[this.state.menukey],
        groupType: 1,
        pageNo: 1,
        pageSize: 7
      }
    })
    .then((response)=> {
      try {
        if (response.data.data.entities) {
          this.setState({
            data: response.data.data.entities,
          })
        }
      } catch (err) {
        console.log(err);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }


  handleChange = (key) => {
    axios.get(`/hr/api/v1/newsTypes/selectNewsType`, {
      params: {
        groupCode: params[key],
        groupType: (key === 2 || key === '2') ? 2 : 1,
        pageNo: 1,
        pageSize: 7
      }
    })
    .then((response)=> {
      try {
        if (response.data.data.entities) {
          this.setState({
            data: response.data.data.entities,
            total: response.data.data.entityCount,
            menukey: key
          })
        }
      } catch (err) {
        console.log(err);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  render() {
    let news = [];
    const { isMobile } = this.props;
    if (this.state.data) {
      news = this.state.data.map((item, index)=> {
        return (
          <Link key={index} to={`/news/detail/${item.id}`}>
            <div className="content7-item-wrapper" key={index}>
              <span className="content7-bottom-item">{item.name}</span>
              <span className="content7-bottom-time">{moment(item.pushDate).format('YYYY-MM-DD')}</span>
            </div>
          </Link>
        )
      });
    }
    return (
      <Row justify="center">
        <Col className={isMobile ? 'content7-left' : 'content7-left content7-control-width'}>
          {isMobile ? null : (<Link to='/news'><div className="content7-more">更多></div></Link>)}
          <Tabs 
            defaultActiveKey="0" 
            tabBarStyle={isMobile ? tabstyle2 : tabstyle}
            onChange={this.handleChange}
            size="small"
          >
            {
              newsBlock.map((item1, index1)=>{
                return (
                  <TabPane tab={item1} key={index1}>
                    <div className="content7-bottom-wrapper">
                      {
                        news.map((item2, index2)=> {
                          return item2;
                        })
                      }
                    </div>
                  </TabPane>
                )
              })
            }
          </Tabs>
        </Col>
        {
          isMobile ?
          null
            :
          <Col className="content7-right" >
            <HotItem />
          </Col>
        }
      </Row>
    )
  }
  
}
import React from 'react';
import axios from 'axios';
import { Row } from 'antd';
import icon from '../../img/home/icon.png';
import { Link } from 'react-router-dom';



export default class NewsItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: []
    }
  }

  componentDidMount() {
    axios.get('/hr/api/v1/signUps/listAll')
    .then((response)=> {
      try {
        if (response.data.data) {
          this.setState({
            data: response.data.data,
          })
        }
      } catch (err) {
        console.log(err);
      }
    })
    .catch((error)=> {
      console.log(error);
    });
  }



  render() {
    const { isMobile } = this.props;
    return (
      <Row justify="center">
        <div className="content116-content-wrapper">
          <div className="content116-top-wrapper">
            <span>
              <span className="content116-new-title">最新考务报名</span>
              <img src={icon} alt="test" className="content116-icon" />
            </span>
            <span className="content116-more"><Link to="/news/takeApplication">更多 {'>'} </Link></span>
          </div>
          <div className="content116-bottom-wrapper">
            {
              this.state.data ? this.state.data.map((item, index)=>{
                return (
                  <a target={ item.link ? '_blank' : '' } href={item.link ? item.link : 'javascript:'}  >
                    <img src={item.imgUrl} alt="test" className="content116-test-img" />
                  </a>
                )
              }) : null
            }
            {/* <a target="_blank" href="http://qzpta7.chinasyks.org.cn/cn_sdhuajie/index.html#/regulations"  >
              <img src={img1} alt="test" className="content116-test-img" />
            </a>
            <a target="_blank" href="http://qzpta7.chinasyks.org.cn/cn_huajie/index.html#/index">
              <img src={img2} alt="test" className="content116-test-img" />
            </a>
            <a href="http://qzpta7.chinasyks.org.cn/cn_sdyaojianju/index.html#/index" target="_blank" >
              <img src={img3} alt="test" className="content116-test-img" />
            </a>
            <a href="https://qzpta7.chinasyks.org.cn:18443/cn_sdhuajie/index.html#/index" target="_blank" >
              <img src={img4} alt="test" className="content116-test-img" />
            </a> */}
          </div>
        </div>
      </Row>
    )
  }
  
}
import React from 'react';
import { getChildrenToRender } from './utils';
import { Row, Col, Divider, Breadcrumb } from 'antd';
import axios from 'axios';
import { Link } from 'react-router-dom';
import moment from 'moment';

import arrow from '../../img/news/arrow.png';


// const url = 'https://testhr.lanxiang.co';
// axios.defaults.baseURL = url;




// const breaditem = [
//   '集团动态',
//   '通知公告',
//   '行业动态',
//   '政策资讯',
//   '人才招聘'
// ];

const hashClass = {
  'notice': '通知公告',
  'publish': '结果公示',
  'groupDynamics': '集团动态',
  'policy': '政策资讯',
  'recruit': '人才招聘',
  'dynamic': '行业动态',
};

const hashCode = {
  'notice': 0,
  'publish': 1,
  'groupDynamics': 2,
  'policy': 3,
  'recruit': 4,
  'dynamic': 5,
};


// const downdata = [
//   {
//     name: '附件下载1',
//     url: '#'
//   },
//   {
//     name: '附件下载2',
//     url: '#'
//   },
//   {
//     name: '附件下载3',
//     url: '#'
//   },
// ];

class Content13 extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      content: '',
      menu: '',
      title: '',
      time: '',
      menukey: 0,
      downdata: []
    }
  }

  componentDidMount() {
    axios.get(`/hr/api/v1/newsInfos/selectNewsInfoByNewsTypeId`, {
      params: {
        newsTypeId: this.props.articleId
      }
    })
      .then((response) => {
        try {
          if (response.data.data) {
            const data = response.data.data
            this.setState({
              content: data.contentHasStyle,
              title: data.title,
              time: data.pushDate,
              menu: hashClass[data.groupCode],
              menukey: hashCode[data.groupCode],
              downdata: data.newsFileListVO,
              browseNum: data.browseNum
            })
          }
        } catch (err) {
          console.log(err);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  render() {
    const { ...props } = this.props;
    const { dataSource, isMobile } = props;
    delete props.dataSource;
    delete props.isMobile;
    const query = {
      pathname: '/news',
      query: this.state.menukey
    }


    return (

      <div {...props}>
        <Row justify="center">
          <Col>
            <div className={isMobile ? 'content1313-right content1313-width-mobile' : 'content1313-right content1313-width'}>
              <Breadcrumb separator=">" className="content1313-breadcrumb">
                <Link to={query} className="content1313-goback"><img src={arrow} alt="img" /></Link>
                <Breadcrumb.Item>新闻资讯</Breadcrumb.Item>
                <Breadcrumb.Item>{this.state.menu}</Breadcrumb.Item>
              </Breadcrumb>
              <Divider style={{ margin: '10px 0 0 0' }} />
              <div className="content1313-article-title">{this.state.title}</div>
              <div className="content1313-article-sub-wrapper">
                <div className="content1313-article-time">发布时间：{moment(this.state.time).format('YYYY-MM-DD HH:mm:ss')}</div>
                <div className="content1313-article-num">浏览次数：{this.state.browseNum} </div>
                <div className="content1313-article-num">发布人：山东华杰人力资源</div>
              </div>
              <div dangerouslySetInnerHTML={{ __html: this.state.content }} className={isMobile ? 'content1313-article-bottom-mobile' : 'content1313-article-bottom'} />
              {
                this.state.downdata.length > 0 ?
                  (
                    <div className="content1313-downfile-wrapper">
                      {
                        this.state.downdata.map((item, index) => {
                          return (
                            <div className="content1313-downfile">
                              <a href={item.url} download="">{`${item.name}（点击下载）`}</a>
                            </div>
                          )
                        })
                      }
                    </div>
                  ) : null
              }

            </div>
          </Col>
        </Row>
        {dataSource.titleWrapper.children.map(getChildrenToRender)}
      </div>
    );
  }
}

export default Content13;

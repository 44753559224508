/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';
import { Link } from 'react-router-dom';
import { BackTop, Affix } from 'antd';
import up from '../../img/up.png';
import tel from '../../img/righttel.png';
import Nav2 from './Nav2';
import Banner3 from './Banner3';
import Feature5 from './Feature5';
import Content111 from './Content111';
import Content11 from './Content11';
import Feature4 from './Feature4';
import Content13 from './Content13';
import Footer1 from './Footer1';

import {
  Nav20DataSource,
  Banner30DataSource,
  Feature50DataSource,
  Content130DataSource,
  Feature40DataSource,
  Content111DataSource,
  Content116DataSource,
  Content131DataSource,
  Content132DataSource,
  Content110DataSource,
  Footer10DataSource,
} from './data.source';
import './less/antMotionStyle.less';


let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location } = window;

const style = {
  height: 40,
  width: 40,
  borderRadius: 4,
  backgroundColor: '#418FFF',
  position: 'absolute',
  left: '100px',
  top: '-200px'
};

const style1 = {
  width: '40px',
  height: '140px',
  backgroundColor: '#418FFF',
  position: 'absolute',
  right: '0',
  color: '#fff',
  borderRadius: 4,
  padding: '5px 15px',
};

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
  }

  render() {
    console.log(this.props)
    let isCurrent = false;
    if (this.props.location.pathname === '/') {
      isCurrent = true;
    }

    const children = [
      <Nav2
        id="Nav2_0"
        key="Nav2_0"
        dataSource={Nav20DataSource}
        isMobile={this.state.isMobile}
        isCurrent={isCurrent}
      />,
      <Banner3
        id="Banner3_0"
        key="Banner3_0"
        dataSource={Banner30DataSource}
        isMobile={this.state.isMobile}
      />,
      <Content111
        id="Content11_0"
        key="Content11_0"
        dataSource={Content116DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature5
        id="Feature5_0"
        key="Feature5_0"
        dataSource={Feature50DataSource}
        isMobile={this.state.isMobile}
      />,
      <Content13
        id="Content13_0"
        key="Content13_0"
        dataSource={Content130DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature4
        id="Feature4_0"
        key="Feature4_0"
        dataSource={Feature40DataSource}
        isMobile={this.state.isMobile}
      />,
      <Content111
        id="Content11_1"
        key="Content11_1"
        dataSource={Content111DataSource}
        isMobile={this.state.isMobile}
      />,
      <Content13
        id="Content13_1"
        key="Content13_1"
        dataSource={Content131DataSource}
        isMobile={this.state.isMobile}
      />,
      <Content13
        id="Content13_2"
        key="Content13_2"
        dataSource={Content132DataSource}
        isMobile={this.state.isMobile}
      />,
      <Content11
        id="Content11_0_1"
        key="Content11_0_1"
        dataSource={Content110DataSource}
        isMobile={this.state.isMobile}
      />,
      <Footer1
        id="Footer1_0"
        key="Footer1_0"
        dataSource={Footer10DataSource}
        isMobile={this.state.isMobile}
      />,
    ];
    if (this.state.isMobile) {
      children.splice(2, 1);
      children.splice(3, 1);
      children.splice(4, 1)
    }
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {
          this.state.isMobile ?
            null
            :
            <div>
              <Affix offsetTop={280} >
                <div style={style1}>
                  <Link to="/about/1">
                    <img src={tel} alt="tel" style={{ position: 'relative', right: '8px', padding: '5px 0' }} />
                    <div style={{ color: '#fff' }}>联系我们</div>
                  </Link>
                </div>
              </Affix>
              <BackTop visibilityHeight={800} ><img src={up} alt="up" style={style} /></BackTop>
            </div>
        }
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}

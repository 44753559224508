import React from 'react';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { getChildrenToRender } from './utils';
import down from '../../img/about/down.png'
import up from '../../img/about/up.png'
import {  Col, Row,  } from 'antd'


class Content1312 extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      show: true,
      isShow: this.props.isShow
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isShow !== this.props.show) {
      this.setState({isShow: this.props.isShow});
    }
  }

  componentDidMount() {
    this.setState({
      isShow: this.props.isShow
    })
  }

  showmore() {
    this.setState({show: false})
  }

  showless() {
    this.setState({show: true})
  }

  render() {
    const { ...props } = this.props;
    const { dataSource } = props;
    delete props.dataSource;
    delete props.isMobile;
    if (this.state.isShow !== 3) {
      return null;
    }else return (
      <OverPack {...props} {...dataSource.OverPack}>
        <QueueAnim
          type="bottom"
          leaveReverse
          key="page"
          delay={[0, 100]}
          {...dataSource.titleWrapper}
        >
          {dataSource.titleWrapper.children.map(getChildrenToRender)}
          <div>
            <Row justify="center">
              <Col className="content1312-item">
                <div className="content1312-item-title"><span className="content1312-left-blue"></span>青岛分公司</div>
                <div className="content1312-item-text1">联系方式: 0532-85843036<br /><span className="content1312-num">18669805979</span></div>
                <div className="content1312-item-text2">服务电话: 18669805979(邴*)</div>
                <div className="content1312-item-text3">邮箱: qingdao@huajierenli.com</div>
                <div className="content1312-item-text4">地址:青岛市市北区连云港路76号安泰广场1204</div>
              </Col>
              <Col className="content1312-item">
                <div className="content1312-item-title"><span className="content1312-left-blue"></span>烟台分公司</div>
                <div className="content1312-item-text1">联系方式: 0535-6287922</div>
                <div className="content1312-item-text2">服务电话: 15589525853(孙萍)</div>
                <div className="content1312-item-text3">邮箱: yantai@huajierenli.com</div>
                <div className="content1312-item-text4">地址:烟台市芝罘区机场路90号人力资源产业园南楼404</div>
              </Col>
              <Col className="content1312-item">
                <div className="content1312-item-title"><span className="content1312-left-blue"></span>济宁分公司</div>
                <div className="content1312-item-text1">联系方式: 0537-2263799<br /><span className="content1312-num">13325197569</span></div>
                <div className="content1312-item-text2">服务电话: 4000-960-970</div>
                <div className="content1312-item-text3">邮箱: jining@huajlerenll.com</div>
                <div className="content1312-item-text4">地址:济宁市市中区太白楼中路17号运河城A座15楼1516室</div>
              </Col>
            </Row>
            <Row justify="center">
                <Col className="content1312-item">
                  <div className="content1312-item-title"><span className="content1312-left-blue"></span>泰安分公司</div>
                  <div className="content1312-item-text1">联系方式: 0538-5377025<br /><span className="content1312-num">13853808853</span></div>
                  <div className="content1312-item-text2">服务电话: 4000-960-970</div>
                  <div className="content1312-item-text3">邮箱：taian@huajierenli.com</div>
                  <div className="content1312-item-text4">地址: 泰安市泰山区东岳大街363号奥来新天第B座907室</div>
                </Col>
                <Col className="content1312-item">
                  <div className="content1312-item-title"><span className="content1312-left-blue"></span>潍坊分公司</div>
                  <div className="content1312-item-text1">联系方式: 0536-8869291<br /><span className="content1312-num">18678023565</span></div>
                  <div className="content1312-item-text2">服务电话: 18678023565(刘丽)</div>
                  <div className="content1312-item-text3">邮箱：weifang@huajierenl.com</div>
                  <div className="content1312-item-text4">地址: 潍坊市奎文区健康街与潍州路领秀杰座713</div>
                </Col>
                <Col className="content1312-item">
                  <div className="content1312-item-title"><span className="content1312-left-blue"></span>淄博分公司</div>
                  <div className="content1312-item-text1">联系方式: 0533-3142219<br /><span className="content1312-num">18560722710</span></div>
                  <div className="content1312-item-text2">服务电话: 18560722710(耿碟)</div>
                  <div className="content1312-item-text3">邮箱：zibo@huajierenli.com</div>
                  <div className="content1312-item-text4">地址: 淄博市张店区新村路99号东方商务中心410室</div>
                </Col>
            </Row>
            <Row justify="center" className={this.state.show ? "content1312-none" : ""}>
                <Col className="content1312-item">
                  <div className="content1312-item-title"><span className="content1312-left-blue"></span>枣庄分公司</div>
                  <div className="content1312-item-text1">联系方式: 0632-3081096<br /><span className="content1312-num">15318058611</span></div>
                  <div className="content1312-item-text2">服务电话: 15318058611(杨阳)</div>
                  <div className="content1312-item-text3">邮箱：zaozhaung@huajierenli.com</div>
                  <div className="content1312-item-text4">地址: 枣庄市市中区光明中路41号得利商务写字楼D215室</div>
                </Col>
                <Col className="content1312-item">
                  <div className="content1312-item-title"><span className="content1312-left-blue"></span>日照分公司</div>
                  <div className="content1312-item-text1">联系方式: 0633-8809369<br /><span className="content1312-num">18663338399</span></div>
                  <div className="content1312-item-text2">服务电话: 18663338399(秦伟)</div>
                  <div className="content1312-item-text3">邮箱：rizhao@ huajierenli.com</div>
                  <div className="content1312-item-text4">地址: 日照市东港区兴海路158号方圆建设大厦603室</div>
                </Col>
                <Col className="content1312-item">
                  <div className="content1312-item-title"><span className="content1312-left-blue"></span>菏泽分公司</div>
                  <div className="content1312-item-text1">联系方式: 0530-6169968<br /><span className="content1312-num">18854002109</span></div>
                  <div className="content1312-item-text2">服务电话: 13465009193(孙娟娟)</div>
                  <div className="content1312-item-text3">邮箱：heze@huajierenli.com</div>
                  <div className="content1312-item-text4">地址: 菏泽市牡丹区中华路永泰大厦2012室</div>
                </Col>
            </Row>
            <Row justify="center" className={this.state.show ? "content1312-none" : ""}>
                <Col className="content1312-item">
                  <div className="content1312-item-title"><span className="content1312-left-blue"></span>聊城分公司</div>
                  <div className="content1312-item-text1">联系方式: 0635-8222304<br /><span className="content1312-num">18863023678</span></div>
                  <div className="content1312-item-text2">服务电话: 18678505590(杨晓敏)</div>
                  <div className="content1312-item-text3">邮箱：liaocheng @huajierenli.com</div>
                  <div className="content1312-item-text4">地址: 聊城市东昌东路中央丽都星光大厦2013室</div>
                </Col>
                <Col className="content1312-item">
                  <div className="content1312-item-title"><span className="content1312-left-blue"></span>德州分公司</div>
                  <div className="content1312-item-text1">联系方式: 0534-2362618<br /><span className="content1312-num">13953116662</span></div>
                  <div className="content1312-item-text2">服务电话: 18653448893(张桂花)</div>
                  <div className="content1312-item-text3">邮箱：dezhou@huajierenli.com</div>
                  <div className="content1312-item-text4">地址: 山东省德州市德城区三八中路279号德州唐人中心C区SOHO13层1号</div>
                </Col>
                <Col className="content1312-item">
                  <div className="content1312-item-title"><span className="content1312-left-blue"></span>东营分公司</div>
                  <div className="content1312-item-text1">联系方式: 0546-7784848<br /><span className="content1312-num">13361412767</span></div>
                  <div className="content1312-item-text2">服务电话: 13361412767(史正雷) 13605468370(王平)</div>
                  <div className="content1312-item-text3">邮箱：dongying@huajierenli.com</div>
                  <div className="content1312-item-text4">地址: 东营市东营区西三路26-1众成时代中心505室(王平) 东营市人力资源服务产业园1802室(史正雷)</div>
                </Col>
            </Row>
            <Row justify="center" className={this.state.show ? "content1312-none" : ""}>
                <Col className="content1312-item">
                  <div className="content1312-item-title"><span className="content1312-left-blue"></span>临沂分公司</div>
                  <div className="content1312-item-text1">联系方式: 0539-8026626<br /><span className="content1312-num">13675398013</span></div>
                  <div className="content1312-item-text2">服务电话: 15092370626(韩娅汝)</div>
                  <div className="content1312-item-text3">邮箱：linyi@huajierenli.com</div>
                  <div className="content1312-item-text4">地址: 临沂市兰山区金雀山路56号银凤大厦18楼1801室</div>
                </Col>
                <Col className="content1312-item">
                  <div className="content1312-item-title"><span className="content1312-left-blue"></span>滨州分公司</div>
                  <div className="content1312-item-text1">联系方式: 0543-3159788<br /><span className="content1312-num">18854331818</span></div>
                  <div className="content1312-item-text2">服务电话: 18705436247(邱延松)</div>
                  <div className="content1312-item-text3">邮箱：binzhou@huajierenli.com</div>
                  <div className="content1312-item-text4">地址: 滨州市黄河十路渤海八路往北50米路西沿街楼三楼</div>
                </Col>
                <Col className="content1312-item">
                  <div className="content1312-item-title"><span className="content1312-left-blue"></span>莱芜分公司</div>
                  <div className="content1312-item-text1">联系方式: 0634-5870610<br /><span className="content1312-num">18266344855</span></div>
                  <div className="content1312-item-text2">服务电话: 4000-960-970</div>
                  <div className="content1312-item-text3">邮箱：laiwu@huajierenli.com</div>
                  <div className="content1312-item-text4">地址: 莱芜市莱城区汶源大街金地凯旋城南门西沿街楼419号</div>
                </Col>
            </Row>
            <Row justify="center" className={this.state.show ? "content1312-none" : ""}>
                <Col className="content1312-item">
                  <div className="content1312-item-title"><span className="content1312-left-blue"></span>威海分公司</div>
                  <div className="content1312-item-text1">联系方式: 0631-3917693<br /><span className="content1312-num">15866312592</span></div>
                  <div className="content1312-item-text2">服务电话: 4000-960-970</div>
                  <div className="content1312-item-text3">邮箱：weihai@ huajierenli.com</div>
                  <div className="content1312-item-text4">地址: 威海经区青岛中路106号九隆大厦14楼1409</div>
                </Col>
                <Col className="content1312-item" style={{visibility: 'hidden'}}>
                  <div className="content1312-item-title"><span className="content1312-left-blue"></span>临沂分公司</div>
                  <div className="content1312-item-text1">联系方式: 0631-3917693<br /><span className="content1312-num">15866312592</span></div>
                  <div className="content1312-item-text2">服务电话: 4000-960-970</div>
                  <div className="content1312-item-text3">邮箱：linyi@huajierenli.com</div>
                  <div className="content1312-item-text4">地址: 临沂市兰山区沂州路与陵园前街交汇北10米路西临沂典当三楼太平洋保险斜对面</div>
                </Col>
                <Col className="content1312-item" style={{visibility: 'hidden'}}>
                  <div className="content1312-item-title"><span className="content1312-left-blue"></span>临沂分公司</div>
                  <div className="content1312-item-text1">联系方式: 0539-8100323<br /><span className="content1312-num">13675398013</span></div>
                  <div className="content1312-item-text2">服务电话: 4000-960-970</div>
                  <div className="content1312-item-text3">邮箱：linyi@huajierenli.com</div>
                  <div className="content1312-item-text4">地址: 临沂市兰山区沂州路与陵园前街交汇北10米路西临沂典当三楼太平洋保险斜对面</div>
                </Col>
            </Row>
          </div>
        </QueueAnim>
        <div className={`${this.state.show ? "" : "content1312-none"} content1312-down`} onClick={this.showmore.bind(this)}>展开查看更多<img src={down} alt="img" /></div>
        <div className={`${this.state.show ? "content1312-none" : ""} content1312-up`} onClick={this.showless.bind(this)}>收起<img src={up} alt="img" /></div>
      </OverPack>
    );
  }
}

export default Content1312;

import React from 'react';
import { Row, Col } from 'antd';
import moment from 'moment';
import axios from 'axios';
import { Link } from 'react-router-dom';

export default class CompanyItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: []
    }
  }


  componentDidMount() {
    axios.get(`/hr/api/v1/newsTypes/selectNewsType`, {
      params: {
        groupCode: 'groupDynamics',
        groupType: 2,
        pageNo: 1,
        pageSize: 4
      }
    })
    .then((response)=> {
      try {
        if (response.data.data.entities) {
          this.setState({
            data: response.data.data.entities,
          })
        }
      } catch (err) {
        console.log(err);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }


  /* 用正则表达式实现html解码*/
  htmlDecodeByRegExp(str){
    var s = "";
    if(str.length == 0) return "";
    s = str.replace(/&amp;/g, "&");
    s = s.replace(/&lt;/g, "<");
    s = s.replace(/&gt;/g, ">");
    s = s.replace(/&nbsp;/g, " ");
    s = s.replace(/&#39;/g, "\'");
    s = s.replace(/&quot;/g, "\"");
    return s;
  }

  render() {
    let news = [];
    news = this.state.data.map((item, index)=>{
      return (
        <Col>
          <Link key={index} to={`/news/detail/${item.id}`} style={{color: '#999999'}}>
            <div className="content131-item-wrapper">
              <div className="content131-left">
                <div className="content131-time-day">{moment(item.pushDate).format('DD')}</div>
                <div className="content131-time-year">{moment(item.pushDate).format('YYYY-MM')}</div>
              </div>
              <div className="content131-right">
                <div className="content131-news-title">{item.name}</div>
                <span className="content131-news-text">{this.htmlDecodeByRegExp(item.content)}</span>
              </div>
            </div>
          </Link>
        </Col>
      )
    })

    return (
      <div>
        <Row justify="center" gutter={[50, 60]}>
          {
            news[0]
          }
          {
            news[1]
          }
        </Row>
        <Row justify="center" gutter={[50, 60]}>
          {
            news[2]
          }
          {
            news[3]
          }
        </Row>
      </div>
    )
  }
}
import React from 'react';
import { Button, Row, Col } from 'antd';
import QueueAnim from 'rc-queue-anim';
import Texty from 'rc-texty';
import 'rc-texty/assets/index.css';
import intro from '../../img/about/jianjie.png';
import hezuo from '../../img/about/hezuo.png';
import honor from '../../img/about/pinpai.png';
import intro2 from '../../img/about/jianjie2.png';
import hezuo2 from '../../img/about/hezuo2.png';
import honor2 from '../../img/about/pinpai2.png';
import { Link } from "react-router-dom"



class Banner extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      clicked: this.props.isShow
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isShow !== this.props.isShow) {
      this.setState({
        clicked: parseInt(nextProps.isShow)
      })
    }
  }

  render() {
    const { ...currentProps } = this.props;
    
    const { dataSource, isMobile } = currentProps;
    delete currentProps.dataSource;
    delete currentProps.isMobile;
    const children = dataSource.textWrapper.children.map((item) => {
      const { name, texty, ...$item } = item;
      if (name.match('button')) {
        return (
          <Button type="primary" key={name} {...$item}>
            {item.children}
          </Button>
        );
      }

      return (
        <div key={name} {...$item}>
          {texty ? (
            <Texty type="mask-bottom">{item.children}</Texty>
          ) : (
            item.children
          )}
        </div>
      );
    });
    return (
      <div {...currentProps} {...dataSource.wrapper}>
        <QueueAnim
          key="QueueAnim"
          type={['bottom', 'top']}
          delay={200}
          {...dataSource.textWrapper}
        >
          {children}
          <div className="banner35-item-wrapper">
            <Row justify="center">
              <Col 
                span={8}
                onClick={()=>{
                  this.props.handleChange(1)
                  this.setState({clicked: 1})
                }}>
                <Link to="/about/1">
                  <div className={this.state.clicked === 1 ? 'banner35-item banner35-clicked' : 'banner35-item'} >
                    <img src={intro} alt="img" className={this.state.clicked === 1 ? 'banner35-none banner35-item-img' : "banner35-item-img"} />
                    <img src={intro2} alt="img" className={this.state.clicked === 1 ? 'banner35-item-img' : "banner35-none banner35-item-img"} />
                    <div className="banner35-item-text">公司简介</div>
                  </div>
                </Link>
              </Col>
              <Col
                span={8}
                onClick={()=>{
                  this.props.handleChange(2)
                  this.setState({clicked: 2})
                }}>
                <Link to="/about/2">
                  <div className={this.state.clicked === 2 ? 'banner35-item banner35-clicked' : 'banner35-item'} >
                    <img src={honor} alt="img" className={this.state.clicked === 2 ? 'banner35-none banner35-item-img' : "banner35-item-img"} />
                    <img src={honor2} alt="img" className={this.state.clicked === 2 ? 'banner35-item-img' : "banner35-none banner35-item-img"} />
                    <div className="banner35-item-text">品牌荣誉</div>
                  </div>
                </Link>
              </Col>
              <Col
                span={8}
                onClick={()=>{
                  this.props.handleChange(3)
                  this.setState({clicked: 3})
                }}>
                <Link to="/about/3">
                  <div className={this.state.clicked === 3 ? 'banner35-item banner35-clicked' : 'banner35-item'} >
                    <img src={hezuo} alt="img" className={this.state.clicked === 3 ? 'banner35-none banner35-item-img' : "banner35-item-img"} />
                    <img src={hezuo2} alt="img" className={this.state.clicked === 3 ? 'banner35-item-img' : "banner35-none banner35-item-img"} />
                    <div className="banner35-item-text">商务合作</div>
                  </div>
                </Link>
              </Col>
            </Row>
          </div>
          
        </QueueAnim>
      </div>
    );
  }
}
export default Banner;

import React from 'react';
// import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { getChildrenToRender } from './utils';

class Content1310 extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      isShow: this.props.isShow
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isShow !== this.props.show) {
      this.setState({isShow: this.props.isShow});
    }
  }

  componentDidMount() {
    this.setState({
      isShow: this.props.isShow
    })
  }

  render() {
    const { ...props } = this.props;
    const { dataSource } = props;
    delete props.dataSource;
    delete props.isMobile;
    if (this.state.isShow !== 2) {
      return null;
    }else return (
      <div {...props} {...dataSource.OverPack}>
        <QueueAnim
          type="bottom"
          leaveReverse
          key="page"
          delay={[0, 100]}
          {...dataSource.titleWrapper}
        >
          {dataSource.titleWrapper.children.map(getChildrenToRender)}
        </QueueAnim>
      </div>
    );
  }
}

export default Content1310;

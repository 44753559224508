import React from 'react';
import { Menu, Dropdown } from 'antd';
import { Link } from 'react-router-dom';

import logo from '../../img/logo.png';
import wxcode from '../../img/wxcode.png';


const menu = (
  <Menu>
    <Menu.Item key="0">
      <Link to='/product/humanresource' ><div className="nav2-specitem">人力资源外包</div></Link>
    </Menu.Item>
    <Menu.Item key="1">
      <Link to='/product/business'><div className="nav2-specitem">业务流程外包</div></Link>
    </Menu.Item>
    <Menu.Item key="2">
      <Link to='/product/recruit' ><div className="nav2-specitem">招聘流程外包</div></Link>
    </Menu.Item>
    <Menu.Item key="3">
      <Link to='/product/labor' ><div className="nav2-specitem">劳务派遣服务</div></Link>
    </Menu.Item>
    <Menu.Item key="4">
      <Link to='/product/file' ><div className="nav2-specitem">档案管理服务</div></Link>
    </Menu.Item>
  </Menu>
);





export const Nav20DataSource = {
  isScrollLink: true,
  wrapper: { className: 'header2 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header2-logo',
    children: <Link to='/'><img src={logo} width="100%" alt="img" /></Link>,
  },
  LinkMenu: {
    className: 'header2-menu',
    children: [
      {
        name: 'linkNav',
        to: '#',
        children: (<Link to='/' style={{color: "#fff"}}>首页</Link>),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71n8tfut',
        to: '当前页面 ID 地址，参考如上',
        children: (
          <Dropdown overlay={menu} trigger={['click']} placement="bottomCenter">
            <a className="nav2-productlink" onClick={e => e.preventDefault()} style={{color: "#fff"}}>
              产品介绍
            </a>
          </Dropdown>
        ),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71sot4p',
        to: '当前页面 ID 地址，参考如上',
        children: (<Link to='/news' style={{color: "#fff"}}>新闻资讯</Link>),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71sot4p',
        to: '当前页面 ID 地址，参考如上',
        children: (<Link to='/about/1' style={{color: "#fff"}}>关于我们</Link>),
        className: 'menu-item',
      },
    ],
  },
  mobileMenu: { className: 'header2-mobile-menu' },
};
export const Banner30DataSource = {
  wrapper: { className: 'banner36' },
  textWrapper: {
    className: 'banner36-text-wrapper',
    children: [
      {
        name: 'nameEn',
        className: 'banner36-name-en',
        children: '',
      },
      {
        name: 'slogan',
        className: 'banner36-slogan',
        children: '华杰最新动态',
        texty: true,
      },
      {
        name: 'name',
        className: 'banner36-name',
        children: '掌握相关资讯的第一动态',
      },
      { name: 'button', className: 'banner36-button', children: (
        <Link to='/about/1' style={{color: "#fff"}}>联系我们</Link>
        ) 
      },
    ],
  },
};
export const Content1313DataSource = {
  OverPack: {
    className: 'home-page-wrapper content1313-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'content',
        children:
          '',
        className: 'title-content',
      },
    ],
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 10,
        className: 'block',
        title: {
          className: 'logo',
          children: (
            <span>
              <img src={logo} alt="logo" className="footer1-logoimg" />
            </span>
          ),
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <div>
                  
                </div>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 8,
        className: 'block',
        title: { children: (<span class="footer1-hotline">咨询热线</span>) },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: '',
              children: (
                <span>
                  <p class="footer1-number">4000-960-970</p>
                </span>
              ),
            },
            {
              name: 'link1',
              href: '',
              children: (
                <span>
                  <p class="footer1-time">周一至周五9:00-18:00</p>
                </span>
              ),
            },
            {
              name: 'link3',
              href: '',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 4,
        className: 'block',
        title: { children: (<span className="footer1-wx">关注我们</span>) },
        childWrapper: {
          children: [
            {
              name: 'link0',
              children: (<img src={wxcode} alt="code" />),
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        山东华杰人力资源管理有限公司 版权所有
        <br />
        <a href="http://www.beian.miit.gov.cn/" target="_blank">备案号： 鲁ICP备11016485号</a>
      </span>
    ),
  },
};



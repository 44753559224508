/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';
import { Link } from 'react-router-dom';
import { BackTop, Affix } from 'antd';
import up from '../../img/up.png';
import tel from '../../img/righttel.png';
import Nav2 from './Nav2';
import Banner3 from './Banner3';
import Content13 from './Content14';
import Footer1 from './Footer1';
import {
  Nav20DataSource,
  Banner30DataSource,
  Content1313DataSource,
  Footer10DataSource,
} from './data.source1';
import './less/antMotionStyle.less';


let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location } = window;

const style = {
  height: 40,
  width: 40,
  borderRadius: 4,
  backgroundColor: '#418FFF',
  position: 'relative',
  left: '100px',
  bottom: '200px'
};

const style1 = {
  width: '40px', 
  height: '140px',
  backgroundColor: '#418FFF', 
  position: 'absolute', 
  right: '0', 
  color: '#fff',
  borderRadius: 4,
  padding: '5px 15px',
};

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
  }
  
  render() {
    let menukey = 0;
    if (this.props.location.query) {
      menukey = this.props.location.query;
    }
    console.log(this.props)
    let isCurrent = false;
    if (this.props.location.pathname === '/news/takeApplication') {
      isCurrent = true;
    }
    const children = [
      <Nav2
        id="Nav2_0"
        key="Nav2_0"
        dataSource={Nav20DataSource}
        isMobile={this.state.isMobile}
        isCurrent={isCurrent}
      />,
      <Banner3
        id="Banner3_0"
        key="Banner3_0"
        dataSource={Banner30DataSource}
        isMobile={this.state.isMobile}
      />,
      <Content13
        id="Content13_0"
        key="Content13_0"
        dataSource={Content1313DataSource}
        isMobile={this.state.isMobile}
        menukey={menukey}
      />,
      <Footer1
        id="Footer1_0"
        key="Footer1_0"
        dataSource={Footer10DataSource}
        isMobile={this.state.isMobile}
      />,
    ];
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
       
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}

import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.less";
import "slick-carousel/slick/slick-theme.less";
import slider1 from '../../img/home/1.png';
import slider2 from '../../img/home/2.png';
import slider3 from '../../img/home/3.png';
import slider4 from '../../img/home/4.png';
import slider5 from '../../img/home/5.png';
import arrowleft from '../../img/home/arrowleft.png';
import arrowright from '../../img/home/arrowright.png';



export default class Sliderarrow2 extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      className: "content132-slider-wrapper",
      arrows: false,
      dots: true
    };
    return (
      <div>
        <img className="content132-arrow-left" onClick={this.previous} src={arrowleft} alt="arrow" />
        <Slider ref={c => (this.slider = c)} {...settings}>
          <div key={1} align="center">
            <h3><img src={slider1} alt="slider" className="content132-slider-img" /></h3>
          </div>
          <div key={2} align="center">
            <h3><img src={slider2} alt="slider" className="content132-slider-img" /></h3>
          </div>
          <div key={3} align="center">
            <h3><img src={slider3} alt="slider" className="content132-slider-img" /></h3>
          </div>
          <div key={4} align="center">
            <h3><img src={slider4} alt="slider" className="content132-slider-img" /></h3>
          </div>
          <div key={5} align="center">
            <h3><img src={slider5} alt="slider" className="content132-slider-img" /></h3>
          </div>
        </Slider>
        <img className="content132-arrow-right" onClick={this.next} src={arrowright} alt="arrow" />
      </div>
    );
  }
}
import React from 'react';
// import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { getChildrenToRender } from './utils';
import map from '../../img/about/map.png'
import address from '../../img/about/dizhi.png'
import tel from '../../img/about/tel.png'
import phone from '../../img/about/phone.png'
import mail from '../../img/about/mail.png'
import {  Col, Row, } from 'antd'
class Content1311 extends React.PureComponent {


  constructor(props) {
    super(props);
    this.state = {
      isShow: this.props.isShow
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isShow !== this.props.show) {
      this.setState({isShow: this.props.isShow});
    }
  }

  componentDidMount() {
    this.setState({
      isShow: this.props.isShow
    })
  }

  render() {
    const { ...props } = this.props;
    const { dataSource, isMobile } = props;
    delete props.dataSource;
    delete props.isMobile;
    const dataChildren = [
      { name: 'title', children: '公司总部', className: 'title-h1' },
      { name: 'content', children: '诚信敬业 进取高效', className: 'title-h2' },
      {
        name: 'content',
        children: (
          <div className="content1311-total-wrapper">
            <Row justify="center">
              <Col lg={12} md={24} className="content1311-system-left"><img src={map} className={isMobile ? 'content1311-left-img content1311-item-none' : 'content1311-left-img'} style={{width: 520, height: 320}} alt="system" /></Col>
              <Col lg={10} md={24} className="content1311-system-right">
                <div className="content1311-system-div">
                  <div className={isMobile ? 'content1311-item-wrapper content1311-position-right' : 'content1311-item-wrapper'}>
                    <div className="content1311-item-title">地址</div>
                    <img src={address} alt="img" className='content1311-item-img' />
                    <div className="content1311-item-text">济南市历下区坤顺路1819号中国济南人力资源服务产业园17层</div>
                  </div>
                  <div className={isMobile ? 'content1311-item-wrapper content1311-position-right' : 'content1311-item-wrapper'}>
                    <div className="content1311-item-title">服务电话</div>
                    <img src={tel} alt="img" className="content1311-item-img" />
                    <div className="content1311-item-text"> 4000-960-970</div>
                  </div>
                  {/* <div className="content1311-item-wrapper">
                    <div className="content1311-item-title">邮箱</div>
                    <img src={mail} alt="img" className="content1311-item-img" />
                    <div className="content1311-item-text">huajlerenli@163.com</div>
                  </div> */}
                </div>
                <div className="content1311-system-div">
                  <div className="content1311-item-wrapper">
                    <div className="content1311-item-title">联系电话</div>
                      <img src={phone} alt="img" className="content1311-item-img" />
                    <div className="content1311-item-text">0531-86560778<br />13455106791</div>
                  </div>
                  <div className="content1311-item-wrapper">
                    <div className="content1311-item-title">邮箱</div>
                    <img src={mail} alt="img" className="content1311-item-img" />
                    <div className="content1311-item-text">huajierenli@163.com</div>
                  </div>
                  {/* <div className="content1311-item-wrapper">
                    <div className="content1311-item-title">传真</div>
                    <img src={cz} alt="img" className="content1311-item-img" />
                    <div className="content1311-item-text">huajierenli@163.com</div>
                  </div> */}
                </div>
              </Col>
            </Row>
          </div>
        ),
        className: 'title-content',
      },
    ]
    if (this.state.isShow !== 3) {
      return null;
    }else return (
      <div {...props} {...dataSource.OverPack}>
        <QueueAnim
          type="bottom"
          leaveReverse
          key="page"
          delay={[0, 100]}
          {...dataSource.titleWrapper}
        >
          {dataChildren.map(getChildrenToRender)}
        </QueueAnim>
      </div>
    );
  }
}

export default Content1311;

import React from 'react';
import { Col, Row, Menu, Dropdown,  } from 'antd';
import { Link } from 'react-router-dom';

import logo from '../../../img/logo.png';
import wxcode from '../../../img/wxcode.png';
import renli from '../../../img/business/renli.png';
import shiwu from '../../../img/business/shiwu.png';
import suopei from '../../../img/business/suopei.png';
import zhengce from '../../../img/business/zhengce.png';



const menu = (
  <Menu>
    <Menu.Item key="0">
      <Link to='/product/humanresource' ><div className="nav2-specitem">人力资源外包</div></Link>
    </Menu.Item>
    <Menu.Item key="1">
      <Link to='/product/business'><div className="nav2-specitem">业务流程外包</div></Link>
    </Menu.Item>
    <Menu.Item key="2">
      <Link to='/product/recruit' ><div className="nav2-specitem">招聘流程外包</div></Link>
    </Menu.Item>
    <Menu.Item key="3">
      <Link to='/product/labor' ><div className="nav2-specitem">劳务派遣服务</div></Link>
    </Menu.Item>
    <Menu.Item key="4">
      <Link to='/product/file' ><div className="nav2-specitem">档案管理服务</div></Link>
    </Menu.Item>
  </Menu>
);


export const Nav20DataSource = {
  isScrollLink: true,
  wrapper: { className: 'header2 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header2-logo',
    children: <Link to='/'><img src={logo} width="100%" alt="img" /></Link>,
  },
  LinkMenu: {
    className: 'header2-menu',
    children: [
      {
        name: 'linkNav',
        to: '#',
        children: (<Link to='/' style={{color: "#fff"}}>首页</Link>),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71n8tfut',
        to: '当前页面 ID 地址，参考如上',
        children: (
          <Dropdown overlay={menu} trigger={['click']} placement="bottomCenter">
            <a className="nav2-productlink" onClick={e => e.preventDefault()} style={{color: "#fff"}}>
              产品介绍
            </a>
          </Dropdown>
        ),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71sot4p',
        to: '当前页面 ID 地址，参考如上',
        children: (<Link to='/news' style={{color: "#fff"}}>新闻资讯</Link>),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71sot4p',
        to: '当前页面 ID 地址，参考如上',
        children: (<Link to='/about/1' style={{color: "#fff"}}>关于我们</Link>),
        className: 'menu-item',
      },
    ],
  },
  mobileMenu: { className: 'header2-mobile-menu' },
};
export const Banner30DataSource = {
  wrapper: { className: 'banner34' },
  textWrapper: {
    className: 'banner34-text-wrapper',
    children: [
      {
        name: 'nameEn',
        className: 'banner34-name-en',
        children: '',
      },
      {
        name: 'slogan',
        className: 'banner34-slogan',
        children: '业务流程外包',
        texty: true,
      },
      {
        name: 'name',
        className: 'banner34-name',
        children: '打造数字化云上蓝图，建立企业智慧人力资源',
      },
      { name: 'button', className: 'banner34-button', children: (
        <Link to='/about/1' style={{color: "#fff"}}>联系我们</Link>
        ) 
      },
    ],
  },
};
export const Feature01DataSource = {
  wrapper: { className: 'home-page-wrapper content01-wrapper' },
  page: { className: 'home-page content01' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ 
      name: 'title', 
      children: '服务内容' 
    },{
      name: 'content',
      children: '从您的关心出发，提供多方位的服务支持',
    }],
  },
  childWrapper: {
    className: 'content01-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content01-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content01-block-item',
          children: [
            {
              name: 'image',
              className: 'content01-block-icon',
              children: (<img src={shiwu} alt="feature" className="feature-img" />),
            },
            {
              name: 'title',
              className: 'content01-block-title',
              children: '事务处理',
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content01-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content01-block-item',
          children: [
            {
              name: 'image',
              className: 'content01-block-icon',
              children: (<img src={zhengce} alt="feature" className="feature-img" />),
            },
            {
              name: 'title',
              className: 'content01-block-title',
              children: '政策服务',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content01-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content01-block-item',
          children: [
            {
              name: 'image',
              className: 'content01-block-icon',
              children: (<img src={suopei} alt="feature" className="feature-img" />),
            },
            {
              name: 'title',
              className: 'content01-block-title',
              children: '索赔管理',
            },
          ],
        },
      },
      {
        name: 'block3',
        className: 'content01-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content01-block-item',
          children: [
            {
              name: 'image',
              className: 'content01-block-icon',
              children: (<img src={renli} alt="feature" className="feature-img" />),
            },
            {
              name: 'title',
              className: 'content01-block-title',
              children: '人力资源',
            },
          ],
        },
      },
    ],
  },
};
export const Content113DataSource = {
  OverPack: {
    className: 'home-page-wrapper content113-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '业务流程外包', className: 'title-h1' },
      {
        name: 'content',
        children:
          'Business Process Outsourcing',
        className: 'title-content',
      },
      {
        name: 'content2',
        children: 'BPO是将职能部门的全部功能（比如事务处理、政策服务、索赔管理、人力资源、财务）都转移给供应商。外包供应商根据服务协议在自己的系统中对这些职能进行管理。一些BPO合同是根据服务水平进行支付的，将供应商的收入与业务绩效或成本节约程度联系起来。',
        className: 'title-content2',
      },
      {
        name: 'content3',
        children: (
          <Link to="/about/1"  style={{color: '#fff'}}><div className="content113-about">联系我们</div></Link>
        ),
        className: 'title-content3',
      },
    ],
  },
  button: {
    className: '',
    children: { a: { className: 'button', href: '#', children: '联系我们' } },
  },
};
export const Content133DataSource = {
  OverPack: {
    className: 'home-page-wrapper content133-wrapper k926lstj87j-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '服务优势', className: 'title-h1' },
      {
        name: 'content',
        children:
          '建立企业智慧人力资源',
        className: 'title-content',
      },
      {
        name: 'content2',
        children: (
          <div>
            <Row justify="center" gutter={[50, 60]}>
              <Col>
                <div className="content133-item-wrapper">
                  <div className="content133-news">
                    <div className="content133-news-title content133-bg1">改善辅助业务</div>
                    <div className="content133-news-text">BPO能有效地改善辅助业务对核心业务的支持作用，这些辅助业务对外承包给专业化公司后，其业务质量能得到显著而迅速的改善，从而对核心业务起到推动作用，增加整体赢利。</div>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="content133-item-wrapper">
                  <div className="content133-news">
                    <div className="content133-news-title content133-bg2">对核心业务的重点管理</div>
                    <div className="content133-news-text">BPO能进一步突出对核心业务的重点管理，同时实现对辅助业务的有效控制。将部分辅助业务外包，有助于公司管理层以的时间和精力，将更多资源投入到核心业务上。</div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row justify="center" gutter={[50, 0]}>
              <Col>
                <div className="content133-item-wrapper">
                  <div className="content133-news">
                    <div className="content133-news-title content133-bg3">增强竞争力</div>
                    <div className="content133-news-text">BPO有利于在新的市场环境中打破传统的行业(业务)界线，与外部公司形成跨业务领域的联合，构成长期的战略伙伴关系，增强彼此的竞争力。</div>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="content133-item-wrapper">
                  <div className="content133-news">
                    <div className="content133-news-title content133-bg4">降低生产成本</div>
                    <div className="content133-news-text">BPO有利于控制和降低生产成本。由于实现了对辅助业务的成本质量控制，对业务进行更新与优化设计，采用先进技术等，因此能有效地控制成本。</div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        ),
        className: 'content133-news',
      },
    ],
  },
};
export const Content110DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper',
    playScale: 0.1,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '成为我们的合作伙伴', className: 'title-h1' },
    ],
  },
  button: {
    className: 'button-wrapper',
    children: { className: 'button',  children: (<Link to='/about/1' >联系我们</Link>) },
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 10,
        className: 'block',
        title: {
          className: 'logo',
          children: (
            <span>
              <img src={logo} alt="logo" className="footer1-logoimg" />
            </span>
          ),
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <div>
                  
                </div>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 8,
        className: 'block',
        title: { children: (<span class="footer1-hotline">咨询热线</span>) },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: '',
              children: (
                <span>
                  <p class="footer1-number">4000-960-970</p>
                </span>
              ),
            },
            {
              name: 'link1',
              href: '',
              children: (
                <span>
                  <p class="footer1-time">周一至周五9:00-18:00</p>
                </span>
              ),
            },
            {
              name: 'link3',
              href: '',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 4,
        className: 'block',
        title: { children: (<span className="footer1-wx">关注我们</span>) },
        childWrapper: {
          children: [
            {
              name: 'link0',
              children: (<img src={wxcode} alt="code" />),
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        山东华杰人力资源管理有限公司 版权所有
        <br />
        <a href="http://www.beian.miit.gov.cn/" target="_blank">备案号： 鲁ICP备11016485号</a>
      </span>
    ),
  },
};



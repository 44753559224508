import React from 'react';
import TweenOne from 'rc-tween-one';
import { Link } from 'react-router-dom';
import { Dropdown, Menu } from 'antd';


const menu = (
  <Menu>
    <Menu.Item key="0">
      <Link to='/product/humanresource' ><div className="nav2-specitem">人力资源外包</div></Link>
    </Menu.Item>
    <Menu.Item key="1">
      <Link to='/product/business'><div className="nav2-specitem">业务流程外包</div></Link>
    </Menu.Item>
    <Menu.Item key="2">
      <Link to='/product/recruit' ><div className="nav2-specitem">招聘流程外包</div></Link>
    </Menu.Item>
    <Menu.Item key="3">
      <Link to='/product/labor' ><div className="nav2-specitem">劳务派遣服务</div></Link>
    </Menu.Item>
    <Menu.Item key="4">
      <Link to='/product/file' ><div className="nav2-specitem">档案管理服务</div></Link>
    </Menu.Item>
  </Menu>
);

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneOpen: false,
    };
  }

  phoneClick = () => {
    const phoneOpen = !this.state.phoneOpen;
    this.setState({
      phoneOpen,
    });
  };

  render() {
    const { dataSource, isMobile, isCurrent, ...props } = this.props;

    const { phoneOpen } = this.state;
    const { LinkMenu } = dataSource;
    const navData = LinkMenu.children;
    // const navChildren = Object.keys(navData).map((key, i) => {
    //   const item = navData[key];
    //   let tag = Link;
    //   const tagProps = {};
    //   if (item.to && item.to.match(/\//g)) {
    //     tagProps.href = item.to;
    //     tag = 'a';
    //     delete item.to;
    //   }
    //   return React.createElement(
    //     tag,
    //     { ...item, ...tagProps, key: i.toString() },
    //     navData[key].children
    //   );
    // });
    const moment = phoneOpen === undefined ? 300 : null;
    return (
      <TweenOne
        component="header"
        animation={{ opacity: 0, type: 'from' }}
        className={isMobile ? 'header2 home-page-wrapper header2-blue-wrapper' : 'header2 home-page-wrapper header2-trans-wrapper'}
        {...props}
      >
        <div
          {...dataSource.page}
          className={`${dataSource.page.className}${phoneOpen ? ' open' : ''}`}
        >
          <TweenOne
            animation={{ x: -30, type: 'from', ease: 'easeOutQuad' }}
            {...dataSource.logo}
          >
            {dataSource.logo.children}
          </TweenOne>
          {isMobile && (
            <div
              {...dataSource.mobileMenu}
              onClick={() => {
                this.phoneClick();
              }}
            >
              <em />
              <em />
              <em />
            </div>
          )}
          <TweenOne
            {...LinkMenu}
            animation={
              isMobile
                ? {
                    height: 0,
                    duration: 300,
                    onComplete: (e) => {
                      if (this.state.phoneOpen) {
                        e.target.style.height = 'auto';
                      }
                    },
                    ease: 'easeInOutQuad',
                  }
                : null
            }
            moment={moment}
            reverse={!!phoneOpen}
          >
            {/* {navChildren} */}
            <Link to='/' style={{color: "#fff"}} className="menu-item">首页</Link>
            <Dropdown overlay={menu} trigger={['click']} placement="bottomCenter" className="menu-item">
              <a className="nav2-productlink" onClick={e => e.preventDefault()} style={{color: "#fff"}}>
                产品介绍
              </a>
          </Dropdown>
          <Link to='/news' style={{color: "#fff"}} className={isCurrent ? 'menu-item header2-default-bgcolor' : 'menu-item'}>新闻资讯</Link>
          <Link to='/about/1' style={{color: "#fff"}} className="menu-item">关于我们</Link>
          </TweenOne>
        </div>
      </TweenOne>
    );
  }
}

export default Header;

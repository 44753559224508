import React from 'react'
import { Col, Row, Menu, Dropdown, Timeline } from 'antd'
import { Link } from 'react-router-dom'

import logo from '../../img/logo.png'
import wxcode from '../../img/wxcode.png'
import leftimg from '../../img/about/leftimg.png'



const menu = (
  <Menu>
    <Menu.Item key="0">
      <Link to='/product/humanresource' ><div className="nav2-specitem">人力资源外包</div></Link>
    </Menu.Item>
    <Menu.Item key="1">
      <Link to='/product/business'><div className="nav2-specitem">业务流程外包</div></Link>
    </Menu.Item>
    <Menu.Item key="2">
      <Link to='/product/recruit' ><div className="nav2-specitem">招聘流程外包</div></Link>
    </Menu.Item>
    <Menu.Item key="3">
      <Link to='/product/labor' ><div className="nav2-specitem">劳务派遣服务</div></Link>
    </Menu.Item>
    <Menu.Item key="4">
      <Link to='/product/file' ><div className="nav2-specitem">档案管理服务</div></Link>
    </Menu.Item>
  </Menu>
);


const timeLineData = [
  {
    time: '2007.01',
    content: '山东华杰人力资源公司成立'
  },
  {
    time: '2009.01',
    content: '济南市2008年度“放心民办职介服务单位'
  },
  {
    time: '2009.01',
    content: '2008年度中国劳务派遣诚信单位'
  },
  {
    time: '2010.01',
    content: '2009年度全国劳务派遣诚信单位'
  },
  {
    time: '2010.03',
    content: '优秀就业服务机构'
  },
  {
    time: '2011.06',
    content: '2009-2010年度优秀党支部'
  },
  {
    time: '2012.05',
    content: '2011年度全国优秀合作供应商（前程无忧）'
  },
  {
    time: '2013.06',
    content: '2012年度优秀人力资源服务机构'
  },
  {
    time: '2014.06',
    content: '济南市2013年度人力资源服务机构优秀单位'
  },
  {
    time: '2015.01',
    content: '2014年”先进党支部”荣誉称号'
  },
  {
    time: '2015.03',
    content: '2014年度全区人社工作劳动人事争议调解先进集体荣誉称号'
  },
  {
    time: '2015.03',
    content: '2014年度济南市“优秀人力资源服务机构”'
  },
  {
    time: '2015.10',
    content: '华杰人力受邀参加2015中国人力资本论坛'
  },
  {
    time: '2015.12',
    content: '全省人力资源诚信服务示范机构'
  },
  {
    time: '2016.06',
    content: '2015年度优秀人力资源服务机构'
  },
  {
    time: '2017.12',
    content: '2017年度全省人力资源服务业十强机构'
  },
  {
    time: '2017.12',
    content: '全国人力资源诚信服务示范机构'
  },
  {
    time: '2018.8',
    content: '2016~2017年度重合同守信用企业'
  },
  {
    time: '2018.10',
    content: '诚信建设示范单位'
  },
  {
    time: '2018.11',
    content: '优秀电子商务服务商'
  },
  {
    time: '2018.12',
    content: '2018年度全省人力资源服务业十大品牌'
  },
  {
    time: '2019.03',
    content: '三八红旗集体'
  },
  {
    time: '2019.04',
    content: '2018年”先进党支部”荣誉称号'
  },
  {
    time: '2019.06',
    content: '推进非公党建工作突出贡献单位'
  },
  {
    time: '2019.06',
    content: '文明诚信民营企业标兵'
  },
  {
    time: '2019.10',
    content: '济南市劳动人事争议调解组织示范单位'
  },
  {
    time: '2020.01',
    content: '2019年”先进党支部”荣誉称号'
  },
  {
    time: '2020.01',
    content: '2019年度成长创新人力资源服务机构'
  },
  {
    time: '2020.11',
    content: '全省助力重大发展战略人力资源服务机构'
  },
  {
    time: '2021.01',
    content: '诚信经营先进企业'
  },
  {
    time: '2021.06',
    content: '2020年”先进党支部”荣誉称号'
  },
]

export const Nav20DataSource = {
  isScrollLink: true,
  wrapper: { className: 'header2 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header2-logo',
    children: <Link to='/'><img src={logo} width="100%" alt="img" /></Link>,
  },
  LinkMenu: {
    className: 'header2-menu',
    children: [
      {
        name: 'linkNav',
        to: '#',
        children: (<Link to='/' style={{ color: "#fff" }}>首页</Link>),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71n8tfut',
        to: '当前页面 ID 地址，参考如上',
        children: (
          <Dropdown overlay={menu} trigger={['click']} placement="bottomCenter">
            <a className="nav2-productlink" href="" onClick={e => e.preventDefault()} style={{ color: "#fff" }}>
              产品介绍
            </a>
          </Dropdown>
        ),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71sot4p',
        to: '当前页面 ID 地址，参考如上',
        children: (<Link to='/news' style={{ color: "#fff" }}>新闻资讯</Link>),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71sot4p',
        to: '当前页面 ID 地址，参考如上',
        children: (<Link to='/about/1' style={{ color: "#fff" }}>关于我们</Link>),
        className: 'menu-item',
      },
    ],
  },
  mobileMenu: { className: 'header2-mobile-menu' },
};
export const Banner30DataSource = {
  wrapper: { className: 'banner35' },
  textWrapper: {
    className: 'banner35-text-wrapper',
    children: [
      {
        name: 'nameEn',
        className: 'banner35-name-en',
        children: '',
      },
      {
        name: 'slogan',
        className: 'banner35-slogan',
        children: '华杰人力',
        texty: true,
      },
      {
        name: 'name',
        className: 'banner35-name',
        children: '综合高效人力资源解决方案服务商',
      },
    ],
  },
};


export const Content114DataSource = {
  OverPack: {
    className: 'home-page-wrapper content114-wrapper',
    playScale: 0.1,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '关于华杰', className: 'title-h1' },
      { name: 'content', children: '诚信、敬业、进取、高效', className: 'title-content' },
      { name: 'content', children: '华杰成立于2007年，是山东地区领先的、富有远见和创新的人力资源服务品牌，是人力资源服务行业的先行者，以济南为核心，包括山东省17地市在内50余家城市分公司，一站式解决方案、网络及现场支持，将我们在业界最为全面的服务资源有效整合，为企业提供全面、创新、灵活、贴心的人力资源“一站式”服务，最大化、最优化的提高企业人力资源管理效益，将您的管理成本降至最低。', className: 'title-content2' },
      { name: 'content', children: '华杰—拥有一支高效、严谨、专业的管理团队，凭借丰富的行业经验，为客户提供全面的人事外包、人才派遣、招聘猎头、薪酬福利、劳动法务外包、项目外包、咨询培训等全方位、多层次管理解决方案。以完善的项目服务架构，规范化的服务标准，先进的服务理念，让人力资本实现最大价值，为企业发展和社会进步尽华杰人的绵薄之力。', className: "title-content3" },
    ],
  },
  button: {
    className: 'button-wrapper',
    children: { a: { className: 'button', href: '#', children: '' } },
  },
};
export const Content139DataSource = {
  OverPack: {
    className: 'home-page-wrapper content139-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '品牌诠释', className: 'title-h1' },
      { name: 'content', children: '诚信、敬业、进取、高效', className: 'title-h2' },
      {
        name: 'content',
        children: (
          <div>
            <Row justify="center" gutter={20}>
              <Col lg={12} md={24} className="content139-system-right"><img src={leftimg} className="content139-right-img" alt="system" /></Col>
              <Col lg={10} md={24} className="content139-system-left">
                <div className="content139-text-wrapper">
                  <div className="content139-text-content">华杰人付出的汗水和智慧为您铺就提升客户人力资源价值的捷径之路。</div>
                  <div className="content139-text-content">华杰人全面、完善的服务，能够保障客户人力资源战略的落地实施。</div>
                  <div className="content139-text-content">华杰人值得您托付，我们有以工匠精神造就的忠实于客户的优秀团队。</div>
                </div>
              </Col>
            </Row>
          </div>
        ),
        className: 'title-content',
      },
    ],
  },
};
export const Content115DataSource = {
  OverPack: {
    className: 'home-page-wrapper content115-wrapper',
    playScale: 0.1,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '成为我们的合作伙伴', className: 'title-h1' },
      { name: 'content', children: '全面、创新、灵活、贴心的人力资源', className: 'title-content' },
    ],
  },
  button: {
    className: 'button-wrapper',
    children: { a: { className: 'button', href: '#', children: (<Link to='/about' >联系我们</Link>) } },
  },
};




export const Content1310DataSource = {
  OverPack: {
    className: 'home-page-wrapper content1310-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '我们的荣誉', className: 'title-h1' },
      { name: 'content', children: '诚信、敬业、进取、高效', className: 'title-h2' },
      {
        name: 'content',
        children: (
          <div className="content1310-timeline-wrapper">
            <Row justify="center">
              <Col>
                <Timeline mode="alternate" >
                  {
                    timeLineData.map((item, index) => {
                      return (
                        <Timeline.Item key={item.time} color="gray" label={item.time}>
                          {item.content}
                        </Timeline.Item>
                      )
                    })
                  }
                </Timeline>
              </Col>
            </Row>
          </div>
        ),
        className: 'title-content',
      },
    ],
  },
};



export const Content1311DataSource = {
  OverPack: {
    className: 'home-page-wrapper content1311-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '公司总部', className: 'title-h1' },
      { name: 'content', children: '诚信敬业 进取高效', className: 'title-h2' },
      {
        name: 'content',
        children: (
          <div></div>
        ),
        className: 'title-content',
      },
    ],
  },
};
export const Content1312DataSource = {
  OverPack: {
    className: 'home-page-wrapper content1312-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '华杰各地', className: 'title-h1' },
      { name: 'content', children: '诚信敬业 进取高效', className: 'title-h2' },
      {
        name: 'content',
        children: (
          <div>
            {/* <Row justify="center">
              <Col className="content1312-item">
                <div className="content1312-item-title"><span className="content1312-left-blue"></span>青岛分公司</div>
                <div className="content1312-item-text1">联系方式: 0532-85843036<br /><span className="content1312-num">18669805979</span></div>
                <div className="content1312-item-text2">服务电话: 4000-960-970</div>
                <div className="content1312-item-text3">邮箱: qingdao@huajierenli.com</div>
                <div className="content1312-item-text4">地址:青岛市市南区徐州路98号丙2号楼503</div>
              </Col>
              <Col className="content1312-item">
                <div className="content1312-item-title"><span className="content1312-left-blue"></span>烟台分公司</div>
                <div className="content1312-item-text1">联系方式: 15589525853</div>
                <div className="content1312-item-text2">服务电话: 4000-960-970</div>
                <div className="content1312-item-text3">邮箱: yantai@huajierenli.com</div>
                <div className="content1312-item-text4">地址:烟台市芝罘区机场路80号九开家居5层520</div>
              </Col>
              <Col className="content1312-item">
                <div className="content1312-item-title"><span className="content1312-left-blue"></span>济宁分公司</div>
                <div className="content1312-item-text1">联系方式: 0537-2263799<br /><span className="content1312-num">13325197569</span></div>
                <div className="content1312-item-text2">服务电话: 4000-960-970</div>
                <div className="content1312-item-text3">邮箱: jining@huajlerenll.com</div>
                <div className="content1312-item-text4">地址:济宁市市中区太白楼中路17号运河城A座15楼1516室</div>
              </Col>
            </Row>
            <Row justify="center">
                <Col className="content1312-item">
                  <div className="content1312-item-title"><span className="content1312-left-blue"></span>泰安分公司</div>
                  <div className="content1312-item-text1">联系方式: 0538-5377025<br /><span className="content1312-num">13853808853</span></div>
                  <div className="content1312-item-text2">服务电话: 4000-960-970</div>
                  <div className="content1312-item-text3">邮箱：taian@huajierenli.com</div>
                  <div className="content1312-item-text4">地址: 泰安市泰山区东岳大街363号奥来新天第B座907室</div>
                </Col>
                <Col className="content1312-item">
                  <div className="content1312-item-title"><span className="content1312-left-blue"></span>潍坊分公司</div>
                  <div className="content1312-item-text1">联系方式: 0536-8869291<br /><span className="content1312-num">18678023565</span></div>
                  <div className="content1312-item-text2">服务电话: 4000-960-970</div>
                  <div className="content1312-item-text3">邮箱：weifang@huajierenl.com</div>
                  <div className="content1312-item-text4">地址: 潍坊市奎文区健康街与潍州路领秀杰作618室</div>
                </Col>
                <Col className="content1312-item">
                  <div className="content1312-item-title"><span className="content1312-left-blue"></span>淄博分公司</div>
                  <div className="content1312-item-text1">联系方式: 0533-3142219<br /><span className="content1312-num">18560722710</span></div>
                  <div className="content1312-item-text2">服务电话: 4000-960-970</div>
                  <div className="content1312-item-text3">邮箱：zibo@huajierenli.com</div>
                  <div className="content1312-item-text4">地址: 淄博市张店区华光路200号玉龙大厦A座1106室</div>
                </Col>
            </Row>
            <Row justify="center">
                <Col className="content1312-item">
                  <div className="content1312-item-title"><span className="content1312-left-blue"></span>枣庄分公司</div>
                  <div className="content1312-item-text1">联系方式: 0632-3081096<br /><span className="content1312-num">15318058611</span></div>
                  <div className="content1312-item-text2">服务电话: 4000-960-970</div>
                  <div className="content1312-item-text3">邮箱：zaozhaung@huajierenli.com</div>
                  <div className="content1312-item-text4">地址: 枣庄市市中区君山路333号得亿写字楼206室</div>
                </Col>
                <Col className="content1312-item">
                  <div className="content1312-item-title"><span className="content1312-left-blue"></span>日照分公司</div>
                  <div className="content1312-item-text1">联系方式: 0633-8809369<br /><span className="content1312-num">18663338399</span></div>
                  <div className="content1312-item-text2">服务电话: 4000-960-970</div>
                  <div className="content1312-item-text3">邮箱：rizhao@ huajierenli.com</div>
                  <div className="content1312-item-text4">地址: 山东省日照市东港区兴海路158号方圆建设集团413室</div>
                </Col>
                <Col className="content1312-item">
                  <div className="content1312-item-title"><span className="content1312-left-blue"></span>菏泽分公司</div>
                  <div className="content1312-item-text1">联系方式: 0530-6690696<br /><span className="content1312-num">18854002109</span></div>
                  <div className="content1312-item-text2">服务电话: 4000-960-970</div>
                  <div className="content1312-item-text3">邮箱：heze@huajierenli.com</div>
                  <div className="content1312-item-text4">地址: 山东省菏泽市牡丹区北城句阳路西侧</div>
                </Col>
            </Row>
            <Row justify="center">
                <Col className="content1312-item">
                  <div className="content1312-item-title"><span className="content1312-left-blue"></span>聊城分公司</div>
                  <div className="content1312-item-text1">联系方式: 0635- 8222304<br /><span className="content1312-num">18863023678</span></div>
                  <div className="content1312-item-text2">服务电话: 4000-960-970</div>
                  <div className="content1312-item-text3">邮箱：liaocheng @huajierenli.com</div>
                  <div className="content1312-item-text4">地址: 聊城市东昌府区东昌东路市委对过育新苑小区临街楼514室</div>
                </Col>
                <Col className="content1312-item">
                  <div className="content1312-item-title"><span className="content1312-left-blue"></span>德州分公司</div>
                  <div className="content1312-item-text1">联系方式: 0534-2362618<br /><span className="content1312-num">13953116662</span></div>
                  <div className="content1312-item-text2">服务电话: 4000-960-970</div>
                  <div className="content1312-item-text3">邮箱：dezhou@huajierenli.com</div>
                  <div className="content1312-item-text4">地址: 德州市湖滨南大道669号德州万达广场D区公寓C座906</div>
                </Col>
                <Col className="content1312-item">
                  <div className="content1312-item-title"><span className="content1312-left-blue"></span>东营分公司</div>
                  <div className="content1312-item-text1">联系方式: 0546-7784848<br /><span className="content1312-num">13361412767</span></div>
                  <div className="content1312-item-text2">服务电话: 4000-960-970</div>
                  <div className="content1312-item-text3">邮箱：dongying@huajierenli.com</div>
                  <div className="content1312-item-text4">地址: 山东省东营市东营区西三路26- 1众成时代中心505室</div>
                </Col>
            </Row>
            <Row justify="center">
                <Col className="content1312-item">
                  <div className="content1312-item-title"><span className="content1312-left-blue"></span>临沂分公司</div>
                  <div className="content1312-item-text1">联系方式: 0539-8100323<br /><span className="content1312-num">13675398013</span></div>
                  <div className="content1312-item-text2">服务电话: 4000-960-970</div>
                  <div className="content1312-item-text3">邮箱：linyi@huajierenli.com</div>
                  <div className="content1312-item-text4">地址: 临沂市兰山区沂州路与陵园前街交汇北10米路西临沂典当三楼太平洋保险斜对面</div>
                </Col>
                <Col className="content1312-item">
                  <div className="content1312-item-title"><span className="content1312-left-blue"></span>滨州分公司</div>
                  <div className="content1312-item-text1">联系方式: 0543-3159788<br /><span className="content1312-num">18854331818</span></div>
                  <div className="content1312-item-text2">服务电话: 4000-960-970</div>
                  <div className="content1312-item-text3">邮箱：binzhou@huajierenli.com</div>
                  <div className="content1312-item-text4">地址: 滨州市黄河十路渤海八路往北50米路西沿街楼三楼</div>
                </Col>
                <Col className="content1312-item">
                  <div className="content1312-item-title"><span className="content1312-left-blue"></span>莱芜分公司</div>
                  <div className="content1312-item-text1">联系方式: 0634-5870610<br /><span className="content1312-num">18266344855</span></div>
                  <div className="content1312-item-text2">服务电话: 4000-960-970</div>
                  <div className="content1312-item-text3">邮箱：laiwu@huajierenli.com</div>
                  <div className="content1312-item-text4">地址: 莱芜市莱城区汶源大街金地凯旋城南门西沿街楼419号</div>
                </Col>
            </Row>
            <Row justify="center">
                <Col className="content1312-item">
                  <div className="content1312-item-title"><span className="content1312-left-blue"></span>威海分公司</div>
                  <div className="content1312-item-text1">联系方式: 0631-3917693<br /><span className="content1312-num">15866312592</span></div>
                  <div className="content1312-item-text2">服务电话: 4000-960-970</div>
                  <div className="content1312-item-text3">邮箱：weihai@ huajierenli.com</div>
                  <div className="content1312-item-text4">地址: 威海经区青岛中路106号九隆大厦14楼1409</div>
                </Col>
                <Col className="content1312-item" style={{visibility: 'hidden'}}>
                  <div className="content1312-item-title"><span className="content1312-left-blue"></span>临沂分公司</div>
                  <div className="content1312-item-text1">联系方式: 0631-3917693<br /><span className="content1312-num">15866312592</span></div>
                  <div className="content1312-item-text2">服务电话: 4000-960-970</div>
                  <div className="content1312-item-text3">邮箱：linyi@huajierenli.com</div>
                  <div className="content1312-item-text4">地址: 临沂市兰山区沂州路与陵园前街交汇北10米路西临沂典当三楼太平洋保险斜对面</div>
                </Col>
                <Col className="content1312-item" style={{visibility: 'hidden'}}>
                  <div className="content1312-item-title"><span className="content1312-left-blue"></span>临沂分公司</div>
                  <div className="content1312-item-text1">联系方式: 0539-8100323<br /><span className="content1312-num">13675398013</span></div>
                  <div className="content1312-item-text2">服务电话: 4000-960-970</div>
                  <div className="content1312-item-text3">邮箱：linyi@huajierenli.com</div>
                  <div className="content1312-item-text4">地址: 临沂市兰山区沂州路与陵园前街交汇北10米路西临沂典当三楼太平洋保险斜对面</div>
                </Col>
            </Row> */}
          </div>
        ),
        className: 'title-content',
      },
    ],
  },
};




export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 10,
        className: 'block',
        title: {
          className: 'logo',
          children: (
            <span>
              <img src={logo} alt="logo" className="footer1-logoimg" />
            </span>
          ),
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <div>

                </div>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 8,
        className: 'block',
        title: { children: (<span class="footer1-hotline">咨询热线</span>) },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: '',
              children: (
                <span>
                  <p class="footer1-number">4000-960-970</p>
                </span>
              ),
            },
            {
              name: 'link1',
              href: '',
              children: (
                <span>
                  <p class="footer1-time">周一至周五9:00-18:00</p>
                </span>
              ),
            },
            {
              name: 'link3',
              href: '',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 4,
        className: 'block',
        title: { children: (<span className="footer1-wx">关注我们</span>) },
        childWrapper: {
          children: [
            {
              name: 'link0',
              children: (<img src={wxcode} alt="code" />),
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        山东华杰人力资源管理有限公司 版权所有
        <br />
        <a href="http://www.beian.miit.gov.cn/" target="_blank">备案号： 鲁ICP备11016485号</a>
      </span>
    ),
  },
};


/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';
import { Link } from 'react-router-dom';
import { BackTop, Affix } from 'antd';
import up from '../../img/up.png';
import tel from '../../img/righttel.png';
import Nav2 from './Nav2';
import Banner3 from './Banner3';
import Content139 from './Content139';
import Content1310 from './Content1310';
import Content1311 from './Content1311';
import Content1312 from './Content1312';
import Content11 from './Content11';
import Content115 from './Content115';
import Footer1 from './Footer1';

import {
  Nav20DataSource,
  Banner30DataSource,


  Content114DataSource,
  Content139DataSource,
  Content115DataSource,


  Content1310DataSource,

  
  Content1311DataSource,
  Content1312DataSource,


  Footer10DataSource,
} from './data.source';
import './less/antMotionStyle.less';


let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location } = window;

const style = {
  height: 40,
  width: 40,
  borderRadius: 4,
  backgroundColor: '#418FFF',
  position: 'relative',
  left: '100px',
  bottom: '200px'
};

const style1 = {
  width: '40px', 
  height: '140px',
  backgroundColor: '#418FFF', 
  position: 'absolute', 
  right: '0', 
  color: '#fff',
  borderRadius: 4,
  padding: '5px 15px',
};

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
      isShow: 1,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      this.setState({
        isShow: parseInt(nextProps.match.params.id)
      })
    }
  }

  componentDidMount() {
    
    if (this.props.match.params.id) {
      this.setState({
        isShow: parseInt(this.props.match.params.id)
      })
    }
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
  }

  handleChange(isShow) {
    this.setState({
      isShow
    })
  }

  render() {
    let isCurrent = false;
    if (this.props.location.pathname.substring(0, 6) === '/about') {
      isCurrent = true;
    }
    const children = [
      <Nav2
        id="Nav2_0"
        key="Nav2_0"
        dataSource={Nav20DataSource}
        isMobile={this.state.isMobile}
        isCurrent={isCurrent}
      />,
      <Banner3
        id="Banner3_0"
        key="Banner3_0"
        dataSource={Banner30DataSource}
        isMobile={this.state.isMobile}
        handleChange={this.handleChange.bind(this)}
        isShow={this.state.isShow}
      />,


      <Content11
        id="Content11_0"
        key="Content11_0"
        dataSource={Content114DataSource}
        isMobile={this.state.isMobile}
        isShow={this.state.isShow}
      />,
      <Content139
        id="Content13_9"
        key="Content13_9"
        dataSource={Content139DataSource}
        isMobile={this.state.isMobile}
        isShow={this.state.isShow}
      />,
      



      <Content1310
        id="Content13_10"
        key="Content13_10"
        dataSource={Content1310DataSource}
        isMobile={this.state.isMobile}
        isShow={this.state.isShow}
      />,
      


      <Content1311
        id="Content13_11"
        key="Content13_11"
        dataSource={Content1311DataSource}
        isMobile={this.state.isMobile}
        isShow={this.state.isShow}
      />,
      <Content1312
        id="Content13_12"
        key="Content13_12"
        dataSource={Content1312DataSource}
        isMobile={this.state.isMobile}
        isShow={this.state.isShow}
      />,

      <Content115
        id="Content11_5"
        key="Content11_5"
        dataSource={Content115DataSource}
        isMobile={this.state.isMobile}
        isShow={this.state.isShow}
      />,

      <Footer1
        id="Footer1_0"
        key="Footer1_0"
        dataSource={Footer10DataSource}
        isMobile={this.state.isMobile}
      />,
    ];
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {
          this.state.isMobile ?
          null
            :
          <div>
            <Affix offsetTop={280} >
              <div style={style1}>
                <Link to="/about/1">
                  <img src={tel} alt="tel" style={{position: 'relative', right: '8px', padding: '5px 0'}} />
                  <div style={{color: '#fff'}}>联系我们</div>
                </Link>
              </div>
            </Affix>
            <BackTop visibilityHeight={800} ><img src={up} alt="up" style={style} /></BackTop>
          </div>
        }
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}

import React from 'react';
import { Menu, Dropdown,  } from 'antd';
import { Link } from 'react-router-dom';

import logo from '../../../img/logo.png';
import wxcode from '../../../img/wxcode.png';
import businessman from '../../../img/recruit/businessman.png';
import dingwei from '../../../img/recruit/dingwei.png';
import gangwei from '../../../img/recruit/gangwei.png';
import gangwei2 from '../../../img/recruit/gangwei2.png';
import jianli from '../../../img/recruit/jianli.png';
import jianli2 from '../../../img/recruit/jianli2.png';
import jingyan from '../../../img/recruit/jingyan.png';
import sudu from '../../../img/recruit/sudu.png';
import xiangmu from '../../../img/recruit/xiangmu.png';
import xiangmu2 from '../../../img/recruit/xiangmu2.png';
import xiaoyuan from '../../../img/recruit/xiaoyuan.png';
import xiaoyuan2 from '../../../img/recruit/xiaoyuan2.png';
import zhaopin from '../../../img/recruit/zhaopin.png';
import zhaopin2 from '../../../img/recruit/zhaopin2.png';
// import bg from '../../../img/recruit/bg.png';
import arrow from '../../../img/recruit/arrow.png';
import fuwu from '../../../img/recruit/fuwu.png';

const menu = (
  <Menu>
    <Menu.Item key="0">
      <Link to='/product/humanresource' ><div className="nav2-specitem">人力资源外包</div></Link>
    </Menu.Item>
    <Menu.Item key="1">
      <Link to='/product/business'><div className="nav2-specitem">业务流程外包</div></Link>
    </Menu.Item>
    <Menu.Item key="2">
      <Link to='/product/recruit' ><div className="nav2-specitem">招聘流程外包</div></Link>
    </Menu.Item>
    <Menu.Item key="3">
      <Link to='/product/labor' ><div className="nav2-specitem">劳务派遣服务</div></Link>
    </Menu.Item>
    <Menu.Item key="4">
      <Link to='/product/file' ><div className="nav2-specitem">档案管理服务</div></Link>
    </Menu.Item>
  </Menu>
);


export const Nav20DataSource = {
  isScrollLink: true,
  wrapper: { className: 'header2 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header2-logo',
    children: <Link to='/'><img src={logo} width="100%" alt="img" /></Link>,
  },
  LinkMenu: {
    className: 'header2-menu',
    children: [
      {
        name: 'linkNav',
        to: '#',
        children: (<Link to='/' style={{color: "#fff"}}>首页</Link>),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71n8tfut',
        to: '当前页面 ID 地址，参考如上',
        children: (
          <Dropdown overlay={menu} trigger={['click']} placement="bottomCenter">
            <a className="nav2-productlink" onClick={e => e.preventDefault()} style={{color: "#fff"}}>
              产品介绍
            </a>
          </Dropdown>
        ),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71sot4p',
        to: '当前页面 ID 地址，参考如上',
        children: (<Link to='/news' style={{color: "#fff"}}>新闻资讯</Link>),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71sot4p',
        to: '当前页面 ID 地址，参考如上',
        children: (<Link to='/about/1' style={{color: "#fff"}}>关于我们</Link>),
        className: 'menu-item',
      },
    ],
  },
  mobileMenu: { className: 'header2-mobile-menu' },
};
export const Banner30DataSource = {
  wrapper: { className: 'banner33' },
  textWrapper: {
    className: 'banner33-text-wrapper',
    children: [
      {
        name: 'nameEn',
        className: 'banner33-name-en',
        children: '',
      },
      {
        name: 'slogan',
        className: 'banner33-slogan',
        children: '招聘流程外包',
        texty: true,
      },
      {
        name: 'name',
        className: 'banner33-name',
        children: '为企业提供点到点的“一站式”专业招聘服务',
      },
      { name: 'button', className: 'banner33-button', children: (
        <Link to='/about/1' style={{color: "#fff"}}>联系我们</Link>
        ) 
      },
    ],
  },
};
export const Content139DataSource = {
  OverPack: {
    className: 'home-page-wrapper content139-wrapper k926lstj87j-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '为企业提供点到点的“一站式”专业招聘服务', className: 'title-h1' },
      {
        name: 'content2',
        children: (
              <div className="content139-bottom-wrapper">
                <img src={businessman} alt="img" className="content139-left-img" />
                <div className="content139-right" >
                  <div className="content139-right-title">服务简介</div>
                  <div className="content139-right-content">招聘RPO业务是华杰人力面向企业提供的招聘项目整体外包解决方案，为企业提供点到点的“一站式”专业招聘服务。     
                    <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 招聘RPO业务以解决企业招聘需求为目标，对客户提出的招聘需求进行分析和评估，提供最适合的招聘渠道、人才搜寻与人才甄选等方案，直接挖掘目标招聘人群，并协助客户进行简历筛选与初期面试，为客户定位关键候选人，帮助客户以最快速度解决人才招聘问题。</div>
                </div>
              </div>
        ),
        className: 'title-content',
      },
    ],
  },
};
export const Pricing10DataSource = {
  wrapper: { className: 'home-page-wrapper pricing10-wrapper' },
  page: { className: 'home-page pricing10' },
  OverPack: { playScale: 0.3, className: 'pricing10-content-wrapper' },
  titleWrapper: {
    className: 'pricing10-title-wrapper',
    children: [
      { name: 'title', children: '我们提供专业的服务', className: 'pricing10-title-h1' },
      { name: 'content~k9dhfmfgfvr', className: 'pricing10-subtitle', children: '为企业提供点到点的“一站式”专业招聘服务' },
    ],
  },
  block: {
    className: 'pricing10-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'pricing10-block',
        offset: '2',
        children: {
          wrapper: { className: 'pricing10-block-box ' },
          topWrapper: { className: 'pricing10-top-wrapper' },
          name: { className: 'pricing10-name', children: (
            <div>
              <img src={jianli} alt="img" className="pricing10-icon1" />
              <img src={jianli2} alt="img" className="pricing10-icon2" />
            </div>
          ) },
          money: { className: 'pricing10-money', children: '合格简历推荐' },
          content: {
            className: 'pricing10-content',
            children: (
              <span>
                精确度高的简历<br />
                简历筛选<br />
                简历信息审核<br />
                直接传到hr手中<br />
              </span>
            ),
          },
          line: { className: 'pricing10-line' },
          buttonWrapper: {
            className: 'pricing10-button-wrapper',
            children: {
              a: {
                className: 'pricing10-button',
                href: '#',
                children: '',
              },
            },
          },
        },
      },
      {
        name: 'block1',
        className: 'pricing10-block',
        children: {
          wrapper: { className: 'pricing10-block-box ' },
          topWrapper: { className: 'pricing10-top-wrapper' },
          name: { className: 'pricing10-name', children: (
            <div>
              <img src={zhaopin} alt="img" className="pricing10-icon1" />
              <img src={zhaopin2} alt="img" className="pricing10-icon2" />
            </div>
          ) },
          money: { className: 'pricing10-money', children: '专场招聘会' },
          content: {
            className: 'pricing10-content',
            children: (
              <span>
                多部门集团性招聘<br />
                面试时间统一安排<br />
                面试流程高效<br />
              </span>
            ),
          },
          line: { className: 'pricing10-line' },
          buttonWrapper: {
            className: 'pricing10-button-wrapper',
            children: {
              a: {
                className: 'pricing10-button',
                href: '#',
                children: '',
              },
            },
          },
        },
      },
      {
        name: 'block2',
        className: 'pricing10-block',
        children: {
          wrapper: { className: 'pricing10-block-box ' },
          topWrapper: { className: 'pricing10-top-wrapper' },
          name: { className: 'pricing10-name', children: (
            <div>
              <img src={xiangmu} alt="img" className="pricing10-icon1" />
              <img src={xiangmu2} alt="img" className="pricing10-icon2" />
            </div>
          ) },
          money: { className: 'pricing10-money', children: '项目制招聘服务' },
          content: {
            className: 'pricing10-content',
            children: (
              <span>
                紧急批量招聘<br />
                中低端职位<br />
                跨区域全国招聘<br />
                招聘成本低于猎头<br />
              </span>
            ),
          },
          line: { className: 'pricing10-line' },
          buttonWrapper: {
            className: 'pricing10-button-wrapper',
            children: {
              a: {
                className: 'pricing10-button',
                href: '#',
                children: '',
              },
            },
          },
        },
      },
      {
        name: 'block~k9dmue504z',
        className: 'pricing10-block',
        children: {
          wrapper: { className: 'pricing10-block-box ' },
          topWrapper: { className: 'pricing10-top-wrapper' },
          name: { className: 'pricing10-name', children: (
            <div>
              <img src={xiaoyuan} alt="img" className="pricing10-icon1" />
              <img src={xiaoyuan2} alt="img" className="pricing10-icon2" />
            </div>
          ) },
          money: { className: 'pricing10-money', children: '校园RPO' },
          content: {
            className: 'pricing10-content',
            children: (
              <span>
                学生意向清晰<br />
                笔面试人数有保证<br />
                雇主品牌宣传准确<br />
              </span>
            ),
          },
          line: { className: 'pricing10-line' },
          buttonWrapper: {
            className: 'pricing10-button-wrapper',
            children: {
              a: {
                className: 'pricing10-button',
                href: '#',
                children: '',
              },
            },
          },
        },
      },
      {
        name: 'block~k9dmue504z',
        className: 'pricing10-block',
        children: {
          wrapper: { className: 'pricing10-block-box ' },
          topWrapper: { className: 'pricing10-top-wrapper' },
          name: { className: 'pricing10-name', children: (
            <div>
              <img src={gangwei} alt="img" className="pricing10-icon1" />
              <img src={gangwei2} alt="img" className="pricing10-icon2" />
            </div>
          ) },
          money: { className: 'pricing10-money', children: '中高端岗位推荐' },
          content: {
            className: 'pricing10-content',
            children: (
              <span>
                中高端职位推荐<br />
                定向挖掘<br />
                offer<br />
                背景调查<br />
              </span>
            ),
          },
          line: { className: 'pricing10-line' },
          buttonWrapper: {
            className: 'pricing10-button-wrapper',
            children: {
              a: {
                className: 'pricing10-button',
                href: '#',
                children: '',
              },
            },
          },
        },
      },
    ],
  },
};
export const Feature80DataSource = {
  wrapper: { className: 'home-page-wrapper feature8-wrapper' },
  page: { className: 'home-page feature8' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature8-title-wrapper',
    children: [
      { name: 'title', className: 'feature8-title-h1', children: '服务优势' },
      {
        name: 'content',
        className: 'feature8-title-content',
        children: '流程简单清晰，快速响应需求',
      },
    ],
  },
  childWrapper: {
    className: 'feature8-button-wrapper',
    children: [
      {
        name: 'button',
        className: 'feature8-button',
        children: { href: 'https://hr.csxr.cn/#/auth/login?redirect=%2Fstaff%2Femployee', children: '联系我们', target: '_blank' },
      },
    ],
  },
  Carousel: {
    dots: false,
    className: 'feature8-carousel',
    wrapper: { className: 'feature8-block-wrapper' },
    children: {
      className: 'feature8-block',
      titleWrapper: {
        className: 'feature8-carousel-title-wrapper',
        title: { className: 'feature8-carousel-title' },
      },
      children: [
        {
          name: 'block0',
          className: 'feature8-block-row',
          gutter: '4',
          justify: "center",
          title: {
            className: 'feature8-carousel-title-block',
            children: '平台自主训练流程',
          },
          children: [
            {
              className: 'feature8-block-col',
              md: 4,
              xs: 24,
              name: 'child0',
              arrow: {
                className: 'feature8-block-arrow',
                children:`${arrow}`,
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:(<img src={dingwei} alt="img" />),
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '精准定位',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children: '精确科学全面的岗位分析、搜寻策略、选拔流程及跟踪服务',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 4,
              xs: 24,
              name: 'child1',
              arrow: {
                className: 'feature8-block-arrow',
                children:`${arrow}`,
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:(<img src={jingyan} alt="img" />),
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '丰富经验',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '线下行业经验积累，职位人才充分匹配，满足企业多层次需求',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 4,
              xs: 24,
              name: 'child2',
              arrow: {
                className: 'feature8-block-arrow',
                children:`${arrow}`,
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:(<img src={fuwu} alt="img" />),
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '服务团队',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '拥有高素质、高水平、高效率的专业服务团队，服务客户累计万家',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 4,
              xs: 24,
              name: 'child3',
              arrow: {
                className: 'feature8-block-arrow',
                children:`${arrow}`,
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:(<img src={sudu} alt="img" />),
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '响应速度',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '从建立合作到完成招聘进行全程追踪，随时响应企业需求，持续改善执行效率',
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  },
};
export const Content110DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper',
    playScale: 0.1,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '成为我们的合作伙伴', className: 'title-h1' },
    ],
  },
  button: {
    className: 'button-wrapper',
    children: { className: 'button', children: (<Link to='/about/1' >联系我们</Link>)  },
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 10,
        className: 'block',
        title: {
          className: 'logo',
          children: (
            <span>
              <img src={logo} alt="logo" className="footer1-logoimg" />
            </span>
          ),
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <div>
                  
                </div>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 8,
        className: 'block',
        title: { children: (<span class="footer1-hotline">咨询热线</span>) },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: '',
              children: (
                <span>
                  <p class="footer1-number">4000-960-970</p>
                </span>
              ),
            },
            {
              name: 'link1',
              href: '',
              children: (
                <span>
                  <p class="footer1-time">周一至周五9:00-18:00</p>
                </span>
              ),
            },
            {
              name: 'link3',
              href: '',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 4,
        className: 'block',
        title: { children: (<span className="footer1-wx">关注我们</span>) },
        childWrapper: {
          children: [
            {
              name: 'link0',
              children: (<img src={wxcode} alt="code" />),
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        山东华杰人力资源管理有限公司 版权所有
        <br />
        <a href="http://www.beian.miit.gov.cn/" target="_blank">备案号： 鲁ICP备11016485号</a>
      </span>
    ),
  },
};




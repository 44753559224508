
import React from 'react'
import { Route } from 'react-router-dom'
import Home from './views/Home'
// import Community from './views/Product/Community'
import Humanresource from './views/Product/Humanresource'
import Labor from './views/Product/Labor'
import File from './views/Product/File'
import Business from './views/Product/Business'
import Recruit from './views/Product/Recruit'
import News from './views/News'
import NewsDetail from './views/News/Detail'
import TakeApplication from './views/News/takeApplication'

import About from './views/About'
import zhCN from 'antd/es/locale/zh_CN'
import { ConfigProvider } from 'antd'
import ScrollToTop from './ScrollToTop';



function App() {
  return (
    <div className="App">
      <ScrollToTop>
        <ConfigProvider locale={zhCN}>
          <Route path="/" component={Home} exact />
          <Route path="/product/humanresource" component={Humanresource} />
          <Route path="/product/labor" component={Labor} />
          <Route path="/product/file" component={File} />
          <Route path="/product/Business" component={Business} />
          <Route path="/product/Recruit" component={Recruit} />
          <Route exact path="/news" component={News} />
          <Route path="/news/detail/:id" component={NewsDetail} />
          <Route path="/news/takeApplication" component={TakeApplication} />
          <Route path="/about/:id" component={About} />
        </ConfigProvider>
      </ScrollToTop>
      
    </div>
  );
}

export default App;

import React from 'react';
import { Col, Row, Menu, Dropdown } from 'antd';
import { Link } from 'react-router-dom';
import Sliderarrow1 from './Sliderarrow1';
import Sliderarrow2 from './Sliderarrow2';
import SignUp from './SignUp';
import CompanyItem from './CompanyItem';

import logo from '../../img/logo.png';
import wxcode from '../../img/wxcode.png';
import renli from '../../img/home/renli.png';
import baoming from '../../img/home/baoming.png';
import zhaopin from '../../img/home/zhaopin.png';
import renli2 from '../../img/home/renli2.png';
import baoming2 from '../../img/home/baoming2.png';
import zhaopin2 from '../../img/home/zhaopin2.png';
import caigou from '../../img/home/caigou.png';
import caigou2 from '../../img/home/caigou2.png';
import honor from '../../img/home/rongyu.png';
import province from '../../img/home/quansheng.png';
import business from '../../img/home/yewu.png';
import honor2 from '../../img/home/rongyu2.png';
import province2 from '../../img/home/quansheng2.png';
import business2 from '../../img/home/yewu2.png';


const menu = (
  <Menu>
    <Menu.Item key="0">
      <Link to='/product/humanresource' ><div className="nav2-specitem">人力资源外包</div></Link>
    </Menu.Item>
    <Menu.Item key="1">
      <Link to='/product/business'><div className="nav2-specitem">业务流程外包</div></Link>
    </Menu.Item>
    <Menu.Item key="2">
      <Link to='/product/recruit' ><div className="nav2-specitem">招聘流程外包</div></Link>
    </Menu.Item>
    <Menu.Item key="3">
      <Link to='/product/labor' ><div className="nav2-specitem">劳务派遣服务</div></Link>
    </Menu.Item>
    <Menu.Item key="4">
      <Link to='/product/file' ><div className="nav2-specitem">档案管理服务</div></Link>
    </Menu.Item>
  </Menu>
);


export const Nav20DataSource = {
  isScrollLink: true,
  wrapper: { className: 'header2 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header2-logo',
    children: <Link to='/'><img src={logo} width="100%" alt="img" /></Link>,
  },
  LinkMenu: {
    className: 'header2-menu',
    children: [
      {
        name: 'linkNav',
        to: '#',
        children: (<Link to='/' style={{ color: "#fff" }}>首页</Link>),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71n8tfut',
        to: '当前页面 ID 地址，参考如上',
        children: (
          <Dropdown overlay={menu} trigger={['click']} placement="bottomCenter">
            <a className="nav2-productlink" onClick={e => e.preventDefault()} style={{ color: "#fff" }}>
              产品介绍
            </a>
          </Dropdown>
        ),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71sot4p',
        to: '当前页面 ID 地址，参考如上',
        children: (<Link to='/news' style={{ color: "#fff" }}>新闻资讯</Link>),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71sot4p',
        to: '当前页面 ID 地址，参考如上',
        children: (<Link to='/about/1' style={{ color: "#fff" }}>关于我们</Link>),
        className: 'menu-item',
      },
    ],
  },
  mobileMenu: { className: 'header2-mobile-menu' },
};
export const Banner30DataSource = {
  wrapper: { className: 'banner37' },
  textWrapper: {
    className: 'banner37-text-wrapper',
    children: [
      {
        name: 'nameEn',
        className: 'banner37-name-en',
        children: '',
      },
      {
        name: 'slogan',
        className: 'banner37-slogan',
        children: '丰富的人力资源管理经验',
        texty: true,
      },
      {
        name: 'name',
        className: 'banner37-name',
        children: (
          <div>
            <span>打造数字化云上蓝图，建立企业智慧人力资源</span>
            <div className="banner37-bottom-detail">
              <Row justify="center">
                <Col >
                  <div className="banner37-item-wrapper">
                    <img src={renli2} alt="img" className="banner37-item-icon" />
                    <img src={renli} alt="img" className="banner37-item-icon2" />
                    <div className="banner37-item-bottom-wrapper">
                      <div className="banner37-item-title">人力系统</div>
                      <a className="banner37-item-enter" href="https://hr.csxr.cn/#/dashboard" target="_blank">点击进入 {'>'}</a>
                    </div>
                  </div>
                </Col>
                <Col >
                  <div className="banner37-item-wrapper">
                    <img src={zhaopin2} alt="img" className="banner37-item-icon" />
                    <img src={zhaopin} alt="img" className="banner37-item-icon2" />
                    <div className="banner37-item-bottom-wrapper">
                      <div className="banner37-item-title">招聘网站</div>
                      <div className="banner37-item-enter">点击进入 {'>'}</div>
                    </div>
                  </div>
                </Col>
                {/* <a className="banner37-item-enter" href="http://qzpta7.chinasyks.org.cn/cn_sdhuajie3/index.html#/index" target="_blank">点击进入 {'>'}</a> */}
                <Col >
                  <div className="banner37-item-wrapper">
                    <img src={baoming2} alt="img" className="banner37-item-icon" />
                    <img src={baoming} alt="img" className="banner37-item-icon2" />
                    <div className="banner37-item-bottom-wrapper">
                      <div className="banner37-item-title">考务系统</div>
                      {/* <a className="banner37-item-enter" href="http://qzpta7.chinasyks.org.cn/cn_sdyaojianju/index.html#/index" target="_blank">点击进入 ></a> */}
                      <a className="banner37-item-enter" target="_blank">点击进入 ></a>
                    </div>
                  </div>
                </Col>
                <Col >
                  <div className="banner37-item-wrapper">
                    <img src={caigou2} alt="img" className="banner37-item-icon" />
                    <img src={caigou} alt="img" className="banner37-item-icon2" />
                    <div className="banner37-item-bottom-wrapper">
                      <div className="banner37-item-title">福利商城</div>
                      <div className="banner37-item-enter">点击进入 {'>'}</div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        ),
      },
      {
        name: 'button', className: 'banner37-button', children: (
          <Link to='/about/1' style={{ color: "#fff" }}>联系我们</Link>
        )
      },
    ],
  },
};
export const Content116DataSource = {
  OverPack: {
    className: 'home-page-wrapper content116-wrapper',
    playScale: 0.1,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '',
        className: 'title-h1'
      },
      {
        name: 'title',
        children: (
          <SignUp />
        ),
        className: 'content-wrapper'
      },
    ],
  },
};
export const Feature50DataSource = {
  wrapper: { className: 'home-page-wrapper content7-wrapper' },
  page: { className: ' content7' },
  OverPack: {},
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '打造数字化云上蓝图，建立企业智慧人力资源 ',
        className: 'title-h1',
      },
      {
        name: 'content0',
        children: '诚信敬业 进取高效',
        className: 'content7-explain',
      },
      {
        name: 'content',
        children: (
          <div></div>
        ),
        className: 'content7-text-wrapper'
      },
    ],
  },
  tabsWrapper: { className: 'content7-tabs-wrapper' },
};
export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper k926lstj87j-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '我们的产品', className: 'title-h1' },
      {
        name: 'content',
        children:
          '诚信敬业 进取高效',
        className: 'title-content',
      },
      {
        name: 'content1',
        children: (<div></div>),
        className: 'title-bg',
      },
      {
        name: 'content2',
        children: (<Sliderarrow1 />),
        className: 'title-slider',
      },
    ],
  },
};
export const Feature40DataSource = {
  wrapper: { className: 'home-page-wrapper content6-wrapper' },
  OverPack: { className: ' content6' },
  textWrapper: { className: 'content6-text', xs: 24, md: 24 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '我们提供专业的服务',
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: '诚信敬业 进取高效',
      },
    ],
  },
  block: {
    className: 'content6-left-wrapper',
    children: [
      {
        name: 'block0',
        img: {
          children: [`${honor2}`, `${honor}`],
          className: 'content6-icon',
        },
        title: { className: 'content6-title', children: '荣誉与口碑' },
        content: {
          className: 'content6-content',
          children:
            '全国劳务派遣诚信单位、全国人力资源诚信服务示范机构、全省人力源诚信服务示范机构、全省人力资源服务业十强机构、全省人力资源服务十大品牌、济南市优秀人力资源服务机构、放心民办职介服务单位、 先进党支部、推进非公党建突出贡献单位、劳动人事争议调解先进体、三八红旗集体、济南市守合同重信用企业等称号，率先通过ISO9001、14001、GB/T28001等各项国际认证。',
        },
        className: 'content6-item-wrapper'
      },
      {
        name: 'block1',
        img: {
          className: 'content6-icon',
          children: [`${province2}`, `${province}`],
        },
        title: { className: 'content6-title', children: '全省覆盖' },
        content: {
          className: 'content6-content',
          children:
            '公司总部位于山东济南人力资源服务产业园，在全省16地市设立34家全资子公司及分公司，注册资金1000万元，建有党支部、工会、团委、劳动争议仲裁调解委员会，公司运营状况优良，现为山东本土规模最大的提供人力资本定制化解决方案的产业公司。',
        },
        className: 'content6-item-wrapper'
      },
      {
        name: 'block2',
        img: {
          className: 'content6-icon',
          children: [`${business}`, `${business2}`],
        },
        title: { className: 'content6-title', children: '业务全面' },
        content: {
          className: 'content6-content',
          children:
            '公司于2007年7月成立至今，十载拼搏，枝繁叶茂。公司提供的人力资源服务项目已从传统的人事代理、人事派遣、人才招聘逐步扩展到猎头服务、咨询培训、人才测评、业务外包、服务外包、薪酬福利管理、考务外包、档案整理与数字化、软件开发等全方位、多层次的人力资源管理解决服务方案。',
        },
        className: 'content6-item-wrapper'
      },
    ],
  },
};
export const Content111DataSource = {
  OverPack: {
    className: 'home-page-wrapper content111-wrapper',
    playScale: 0.1,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '连接企业，连接人，释放人力资源价值',
        className: 'title-h1'
      },
      {
        name: 'title',
        children: (
          <Row justify="center">
            <Col span={6}>
              <div className="content111-content-title">17</div>
              <div className="content111-content-text">覆盖全省地市</div>
            </Col>
            <Col span={6}>
              <div className="content111-content-title">10,000,000+</div>
              <div className="content111-content-text">注册资本(元)</div>
            </Col>
            <Col span={6}>
              <div className="content111-content-title">40+</div>
              <div className="content111-content-text">企业合作伙伴</div>
            </Col>
          </Row>
        ),
        className: 'content-wrapper'
      },
    ],
  },
};
export const Content131DataSource = {
  OverPack: {
    className: 'home-page-wrapper content131-wrapper k926lstj87j-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '集团动态', className: 'title-h1' },
      {
        name: 'content',
        children:
          '掌握最前沿的新闻资讯',
        className: 'title-content',
      },
      {
        name: 'content2',
        children: (
          <div>
            <CompanyItem />
            <Link to='/news'><div className="content131-more">更多动态></div></Link>
          </div>
        ),
        className: 'content131-news',
      },
    ],
  },
};
export const Content132DataSource = {
  OverPack: {
    className: 'home-page-wrapper content132-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '合作伙伴', className: 'title-h1' },
      {
        name: 'content',
        children:
          '众多企业的一致选择',
        className: 'content132-title-explain',
      },
      {
        name: 'content2',
        children: (<Sliderarrow2 />),
        className: 'title-content2',
      },
    ],
  },
};
export const Content110DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper',
    playScale: 0.1,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '成为我们的合作伙伴', className: 'title-h1' },
    ],
  },
  button: {
    className: 'button-wrapper',
    children: { className: 'button', children: (<Link to='/about/1' >联系我们</Link>) },
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 10,
        className: 'block',
        title: {
          className: 'logo',
          children: (
            <span>
              <img src={logo} alt="logo" className="footer1-logoimg" />
            </span>
          ),
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <div>

                </div>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 8,
        className: 'block',
        title: { children: (<span class="footer1-hotline">咨询热线</span>) },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: '',
              children: (
                <span>
                  <p class="footer1-number">4000-960-970</p>
                </span>
              ),
            },
            {
              name: 'link1',
              href: '',
              children: (
                <span>
                  <p class="footer1-time">周一至周五9:00-18:00</p>
                </span>
              ),
            },
            {
              name: 'link3',
              href: '',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 4,
        className: 'block',
        title: { children: (<span className="footer1-wx">关注我们</span>) },
        childWrapper: {
          children: [
            {
              name: 'link0',
              children: (<img src={wxcode} alt="code" />),
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        山东华杰人力资源管理有限公司 版权所有
        <br />
        <a href="http://www.beian.miit.gov.cn/" target="_blank">备案号： 鲁ICP备11016485号</a>
      </span>
    ),
  },
};

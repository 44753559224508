import React from 'react';
import { Row, Col, Pagination } from 'antd';



class Content14 extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      content: '',
      menu: '',
      title: '',
      time: '',
      menukey: 0,
      data: [{
        gmtModified: '2021-6-2',
        id: "1",
        imgUrl: require("../../img/news/img11.png"),
    },{
        gmtModified: '2021-5-25',
        id: "2",
        imgUrl: require("../../img/news/img22.png"),
    },{
        gmtModified:  '2021-3-10',
        id: "3",
        imgUrl:require( "../../img/news/img33.jpg"),
    },{
        gmtModified: '2021-3-21',
        id: "4",
        imgUrl: require( "../../img/news/img44.png"),
    },{
        gmtModified: '2021-1-26',
        id: "4",
        imgUrl: require( "../../img/news/img55.png"),
    },{
        gmtModified: '2021-3-10',
        id: "4",
        imgUrl: require( "../../img/news/img66.png"),
    }]
    }
  }

 onChange(pageNumber) {
    console.log('Page: ', pageNumber);
  }
  componentDidMount() {
   
  }

  render() {
    const style= {padding:"13px 0"}
    const style1 = {width:'280px', border: '1px solid #E0E0E0',borderTop: '0px solid #E0E0E0', paddingLeft:"10px",fontSize: '14px',color: '#999999',lineHeight: '40px',
    };
    const style2={padding: '50px 0',textAlign: 'right'}

    return (
        <div style={style}>
            <Row style={style} justify="center">
                <Col span={18} style={style}>
                    <Row gutter={24 }  >
                        {
                            this.state.data.map(i=>{
                                return (
                                    <Col className="gutter-row" style={style} xs={{ span: 22 , offset: 1 }} lg={{ span: 5 }}>
                                    <div>
                                        <img width='280px' height='140px' src={i.imgUrl} alt=""/>
                                        <div style={style1}>
                                        发布时间：{i.gmtModified}
                                        </div>    
                                    </div>
                                </Col>
                                )
                            })
                        }
                       
                     
                    </Row>
                    <div style={style2}>
                      <Pagination showQuickJumper defaultCurrent={2} total={50} onChange={this.onChange} />
                      </div>
                </Col>
            </Row>
        </div>
    );
  }
}

export default Content14;

import React from 'react';
import { Col, Row, Menu, Dropdown,  } from 'antd';
import { Link } from 'react-router-dom';

import logo from '../../../img/logo.png';
import wxcode from '../../../img/wxcode.png';
import cailiao from '../../../img/labor/cailiao.png';
import gongzi from '../../../img/labor/gongzi.png';
import lizhi from '../../../img/labor/lizhi.png';
import richang from '../../../img/labor/richang.png';
import shencha from '../../../img/labor/shencha.png';
import shiwu from '../../../img/labor/shiwu.png';
import wuxian from '../../../img/labor/wuxian.png';
import zhengce from '../../../img/labor/zhengce.png';
import renyuan from '../../../img/labor/renyuan.png';
import fengxian from '../../../img/labor/fengxian.png';
import guanli from '../../../img/labor/guanli.png';
import number1 from '../../../img/labor/01.png';
import number2 from '../../../img/labor/02.png';
import number3 from '../../../img/labor/03.png';
import bai1 from '../../../img/labor/01-bai.png';
import bai2 from '../../../img/labor/02-bai.png';
import bai3 from '../../../img/labor/03-bai.png';

const menu = (
  <Menu>
    <Menu.Item key="0">
      <Link to='/product/humanresource' ><div className="nav2-specitem">人力资源外包</div></Link>
    </Menu.Item>
    <Menu.Item key="1">
      <Link to='/product/business'><div className="nav2-specitem">业务流程外包</div></Link>
    </Menu.Item>
    <Menu.Item key="2">
      <Link to='/product/recruit' ><div className="nav2-specitem">招聘流程外包</div></Link>
    </Menu.Item>
    <Menu.Item key="3">
      <Link to='/product/labor' ><div className="nav2-specitem">劳务派遣服务</div></Link>
    </Menu.Item>
    <Menu.Item key="4">
      <Link to='/product/file' ><div className="nav2-specitem">档案管理服务</div></Link>
    </Menu.Item>
  </Menu>
);


export const Nav20DataSource = {
  isScrollLink: true,
  wrapper: { className: 'header2 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header2-logo',
    children: <Link to='/'><img src={logo} width="100%" alt="img" /></Link>,
  },
  LinkMenu: {
    className: 'header2-menu',
    children: [
      {
        name: 'linkNav',
        to: '#',
        children: (<Link to='/' style={{color: "#fff"}}>首页</Link>),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71n8tfut',
        to: '当前页面 ID 地址，参考如上',
        children: (
          <Dropdown overlay={menu} trigger={['click']} placement="bottomCenter">
            <a className="nav2-productlink" onClick={e => e.preventDefault()} style={{color: "#fff"}}>
              产品介绍
            </a>
          </Dropdown>
        ),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71sot4p',
        to: '当前页面 ID 地址，参考如上',
        children: (<Link to='/news' style={{color: "#fff"}}>新闻资讯</Link>),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71sot4p',
        to: '当前页面 ID 地址，参考如上',
        children: (<Link to='/about/1' style={{color: "#fff"}}>关于我们</Link>),
        className: 'menu-item',
      },
    ],
  },
  mobileMenu: { className: 'header2-mobile-menu' },
};
export const Banner30DataSource = {
  wrapper: { className: 'banner31' },
  textWrapper: {
    className: 'banner31-text-wrapper',
    children: [
      {
        name: 'nameEn',
        className: 'banner31-name-en',
        children: '',
      },
      {
        name: 'slogan',
        className: 'banner31-slogan',
        children: '劳务派遣服务',
        texty: true,
      },
      {
        name: 'name',
        className: 'banner31-name',
        children: '为企业提供点到点的“一站式”专业招聘服务',
      },
      { name: 'button', className: 'banner31-button', children: (
        <Link to='/about/1' style={{color: "#fff"}}>联系我们</Link>
        ) 
      },
    ],
  },
};
export const Feature00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ 
      name: 'title', 
      children: '服务内容' 
    },{
      name: 'content',
      children: '',
    }],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: (<img src={shencha} alt="feature" className="feature-img" />),
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '审查和签订',
            },
            { name: 'content', children: '负责劳动合同或劳务合同的审查和签订，办理派遣员工的入职手续;' },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: (<img src={cailiao} alt="feature" className="feature-img" />),
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '材料的收集、鉴别和整理',
            },
            {
              name: 'content',
              children: '负责管理派遣员工的人事行政关系、人事档案托管事宜及相关人事档案材料的收集、鉴别和整理;',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: (<img src={richang} alt="feature" className="feature-img" />),
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '日常管理',
            },
            {
              name: 'content',
              children: '劳动合同履行过程中的日常管理',
            },
          ],
        },
      },
      {
        name: 'block3',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: (<img src={shiwu} alt="feature" className="feature-img" />),
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '员工相关事务管理',
            },
            {
              name: 'content',
              children: '负责办理派遣员工的转正定级、职称评定、组织关系、集体户口挂靠相关事务',
            },
          ],
        },
      },
      {
        name: 'block4',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: (<img src={gongzi} alt="feature" className="feature-img" />),
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '工资计算与发放',
            },
            {
              name: 'content',
              children: '负责派遣员工的工资计算与发放，代扣代缴个人所得税',
            },
          ],
        },
      },
      {
        name: 'block5',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: (<img src={lizhi} alt="feature" className="feature-img" />),
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '员工离职管理',
            },
            {
              name: 'content',
              children: '负责办理派遣员工的退岗、解职手续免费为派遣员工进行调岗或推荐就业',
            },
          ],
        },
      },
      {
        name: 'block6',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: (<img src={wuxian} alt="feature" className="feature-img" />),
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '员工的五险一金',
            },
            {
              name: 'content',
              children: '负责派遣员工的养老、工伤、医疗、生育、失业以及住房公积金相关业务等各项社会保险的开户、缴纳、待遇享受',
            },
          ],
        },
      },
      {
        name: 'block7',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: (<img src={zhengce} alt="feature" className="feature-img" />),
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '政策咨询',
            },
            {
              name: 'content',
              children: '为企业提供劳动保障事务的政策咨询，处理劳动纠纷',
            },
          ],
        },
      },
    ],
  },
};
export const Feature41DataSource = {
  wrapper: { className: 'home-page-wrapper content61-wrapper' },
  OverPack: { className: 'home-page content61' },
  textWrapper: { className: 'content61-text', xs: 24, md: 24 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '解决多种服务场景',
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: '科技想象力，数据创造力',
      },
    ],
  },
  // img: {
  //   children: `${contentimg}`,
  //   className: 'content61-img',
  // },
  block: {
    children: [
      {
        name: 'block0',
        img: {
          children:
            `${renyuan}`,
          className: 'content61-icon',
        },
        title: { className: 'content61-title', children: '人员不足' },
        content: {
          className: 'content61-content',
          children:
            '人员编制不足，员工数与工作量不匹配',
        },
      },
      {
        name: 'block1',
        img: {
          className: 'content61-icon',
          children:
          `${guanli}`,
        },
        title: { className: 'content61-title', children: '管理效率低' },
        content: {
          className: 'content61-content',
          children:
            '人员流动大、招聘难、管理效率低，用工成本虚高',
        },
      },
      {
        name: 'block2',
        img: {
          className: 'content61-icon',
          children:
          `${fengxian}`,
        },
        title: { className: 'content61-title', children: '风险高' },
        content: {
          className: 'content61-content',
          children:
            '岗位风险高，用工关系紧张',
        },
      },
    ],
  },
};
export const Content134DataSource = {
  OverPack: {
    className: 'home-page-wrapper content134-wrapper k926lstj87j-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '服务优势', className: 'title-h1' },
      {
        name: 'content',
        children:
          '为企业提供点到点的“一站式”专业招聘服务',
        className: 'title-content',
      },
      {
        name: 'content2',
        children: (
          <Row justify="center" gutter={20}>
            <Col>
              <div className="content134-item">
                <div className="content134-item-title">规范操作,提高响应的效率</div>
                <div className="content134-item-text">帮助人力资源部门从繁重的重复性事务中解脱，专注于核心的战略性工作，从而提升人力资源管理的高度和核心竞争力;提供接触新管理技术的机会，提高响应的速度与效率;规范操作，有效遏制随意性的薪资、员工管理，对管理工作的规范性、公正性起到促进作用;</div>
                <img src={number1} alt="img" className="content134-item-img1" />
                <img src={bai1} alt="img" className="content134-item-img2" />
              </div>
            </Col>
            <Col>
              <div className="content134-item">
                <div className="content134-item-title">降低成本、舒缓资金压力</div>
                <div className="content134-item-text">克服企业很多的规模经济弱点。在国内由于劳工权利意识的提高以及就业安全体系和劳动法令的普及，人事直接间接费用及人工成本不断地爬升，人力资源管理业务外包则可以降低企业风险，摆脱杂务干扰，最终引导企业专心经营核心资源，发展核心竞争优势;</div>
                <img src={number2} alt="img" className="content134-item-img1" />
                <img src={bai2} alt="img" className="content134-item-img2" />
              </div>
            </Col>
            <Col>
              <div className="content134-item">
                <div className="content134-item-title">简化流程、安全可靠</div>
                <div className="content134-item-text">避免大量投资于人才所带来的不确定风险简化流程，节省时间，提高员工满意度。同时简化了流程，更加安全可靠。</div>
                <img src={number3} alt="img" className="content134-item-img1" />
                <img src={bai3} alt="img" className="content134-item-img2" />
              </div>
            </Col>
          </Row>
        ),
        className: 'content134-news',
      },
    ],
  },
};
export const Content110DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper',
    playScale: 0.1,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '成为我们的合作伙伴', className: 'title-h1' },
    ],
  },
  button: {
    className: 'button-wrapper',
    children: {  className: 'button', children: (<Link to='/about/1' >联系我们</Link>)  },
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 10,
        className: 'block',
        title: {
          className: 'logo',
          children: (
            <span>
              <img src={logo} alt="logo" className="footer1-logoimg" />
            </span>
          ),
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <div>
                  
                </div>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 8,
        className: 'block',
        title: { children: (<span class="footer1-hotline">咨询热线</span>) },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: '',
              children: (
                <span>
                  <p class="footer1-number">4000-960-970</p>
                </span>
              ),
            },
            {
              name: 'link1',
              href: '',
              children: (
                <span>
                  <p class="footer1-time">周一至周五9:00-18:00</p>
                </span>
              ),
            },
            {
              name: 'link3',
              href: '',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 4,
        className: 'block',
        title: { children: (<span className="footer1-wx">关注我们</span>) },
        childWrapper: {
          children: [
            {
              name: 'link0',
              children: (<img src={wxcode} alt="code" />),
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        山东华杰人力资源管理有限公司 版权所有
        <br />
        <a href="http://www.beian.miit.gov.cn/" target="_blank">备案号： 鲁ICP备11016485号</a>
      </span>
    ),
  },
};



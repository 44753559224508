import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.less";
import "slick-carousel/slick/slick-theme.less";
import humanresource from '../../img/home/renliziyuan.png';
import recruit from '../../img/home/recruit.png';
import business from '../../img/home/yewuliucheng.png';
import files from '../../img/home/dangan.png';
import labor from '../../img/home/laowupaiqian.png';
import gov from '../../img/home/zhengqi.png';


export default class Sliderarrow1 extends Component {
  render() {
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      className: "content13-slider-wrapper",
      centerMode: true,
      arrows: false,
      dots: true
    };
    return (
      <div>
        <Slider ref={c => (this.slider = c)} {...settings}>
          <div key={1}>
            <h3 className="content13-h3-wrapper">
              <div className="content13-solution-wrapper">
                <div className="content13-img-wrapper"><img src={files} alt="community" className="content13-solution-img" /></div>
                <div className="content13-solution-title">档案管理服务</div>
                <div className="content13-solution-content">个性化的档案仓储空间,根据您的档案储量为您提供专业独立预存区域任您选择，提供多项关于档案存放的专业化服务，务求做到您的仓库您做主！</div>
              </div>
            </h3>
          </div>
          <div key={2}>
            <h3 className="content13-h3-wrapper">
              <div className="content13-solution-wrapper">
                <div className="content13-img-wrapper"><img src={gov} alt="community" className="content13-solution-img" /></div>
                <div className="content13-solution-title">政企招聘</div>
                <div className="content13-solution-content">提供满足公司需求的招聘服务</div>
              </div>
            </h3>
          </div>
          <div key={3}>
            <h3 className="content13-h3-wrapper">
              <div className="content13-solution-wrapper">
                <div className="content13-img-wrapper"><img src={recruit} alt="community" className="content13-solution-img" /></div>
                <div className="content13-solution-title">招聘流程外包</div>
                <div className="content13-solution-content">为客户提供专业人力资源解决方案，包括网络招聘、校园招聘、报纸招聘、招聘RPO业务、猎头服务、人才测评服务等，是拥有政府颁发的人力资源服务许可证的专业服务机构。 </div>
              </div>
            </h3>
          </div>
          <div key={4}>
            <h3 className="content13-h3-wrapper">
              <div className="content13-solution-wrapper">
                <div className="content13-img-wrapper"><img src={humanresource} alt="community" className="content13-solution-img" /></div>
                <div className="content13-solution-title">人力资源外包</div>
                <div className="content13-solution-content">是指企业根据需求将一项或几项人力资源管理工作或职能外包出去，由专业的第三方代理，以降低人力成本，实现人力资源管理效率最大化的一种新型人力资源服务。</div>
              </div>
            </h3>
          </div>
          <div key={5}>
            <h3 className="content13-h3-wrapper">
              <div className="content13-solution-wrapper">
                <div className="content13-img-wrapper"><img src={business} alt="community" className="content13-solution-img" /></div>
                <div className="content13-solution-title">业务流程外包</div>
                <div className="content13-solution-content">是指企业根据需求，将企业组织的部分或全部职能部门外包给与人力资源公司，如事务处理、政策服务、索赔管理、人力资源、财务等。</div>
              </div>
            </h3>
          </div>
          <div key={6}>
            <h3 className="content13-h3-wrapper">
              <div className="content13-solution-wrapper">
                <div className="content13-img-wrapper"><img src={labor} alt="community" className="content13-solution-img" /></div>
                <div className="content13-solution-title">劳务派遣服务</div>
                <div className="content13-solution-content">是指由人才派遣机构与派遣员工签订劳动合同，派遣员工为用人企业提供劳务的一种新型用工模式。</div>
              </div>
            </h3>
          </div>
        </Slider>
      </div>
    );
  }
}
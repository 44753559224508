import React from 'react';
import { Col, Row, Menu, Dropdown,  } from 'antd';
import { Link } from 'react-router-dom';
import Sliderarrow2 from './Sliderarrow2';


import logo from '../../../img/logo.png';
import wxcode from '../../../img/wxcode.png';
import rightimg from '../../../img/file/kufang.png';
import leftimg from '../../../img/file/fuwu.png';



const menu = (
  <Menu>
    <Menu.Item key="0">
      <Link to='/product/humanresource' ><div className="nav2-specitem">人力资源外包</div></Link>
    </Menu.Item>
    <Menu.Item key="1">
      <Link to='/product/business'><div className="nav2-specitem">业务流程外包</div></Link>
    </Menu.Item>
    <Menu.Item key="2">
      <Link to='/product/recruit' ><div className="nav2-specitem">招聘流程外包</div></Link>
    </Menu.Item>
    <Menu.Item key="3">
      <Link to='/product/labor' ><div className="nav2-specitem">劳务派遣服务</div></Link>
    </Menu.Item>
    <Menu.Item key="4">
      <Link to='/product/file' ><div className="nav2-specitem">档案管理服务</div></Link>
    </Menu.Item>
  </Menu>
);


export const Nav20DataSource = {
  isScrollLink: true,
  wrapper: { className: 'header2 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header2-logo',
    children: <Link to='/'><img src={logo} width="100%" alt="img" /></Link>,
  },
  LinkMenu: {
    className: 'header2-menu',
    children: [
      {
        name: 'linkNav',
        to: '#',
        children: (<Link to='/' style={{color: "#fff"}}>首页</Link>),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71n8tfut',
        to: '当前页面 ID 地址，参考如上',
        children: (
          <Dropdown overlay={menu} trigger={['click']} placement="bottomCenter">
            <a className="nav2-productlink" onClick={e => e.preventDefault()} style={{color: "#fff"}}>
              产品介绍
            </a>
          </Dropdown>
        ),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71sot4p',
        to: '当前页面 ID 地址，参考如上',
        children: (<Link to='/news' style={{color: "#fff"}}>新闻资讯</Link>),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71sot4p',
        to: '当前页面 ID 地址，参考如上',
        children: (<Link to='/about/1' style={{color: "#fff"}}>关于我们</Link>),
        className: 'menu-item',
      },
    ],
  },
  mobileMenu: { className: 'header2-mobile-menu' },
};
export const Banner30DataSource = {
  wrapper: { className: 'banner32' },
  textWrapper: {
    className: 'banner32-text-wrapper',
    children: [
      {
        name: 'nameEn',
        className: 'banner32-name-en',
        children: '',
      },
      {
        name: 'slogan',
        className: 'banner32-slogan',
        children: '档案管理服务',
        texty: true,
      },
      {
        name: 'name',
        className: 'banner32-name',
        children: '打造数字化云上蓝图，建立企业智慧人力资源',
      },
      { name: 'button', className: 'banner32-button', children: (
        <Link to='/about/1' style={{color: "#fff"}}>联系我们</Link>
        ) 
      },
    ],
  },
};
export const Content136DataSource = {
  OverPack: {
    className: 'home-page-wrapper content136-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '提供多项专业化服务', className: 'title-h1' },
      { name: 'content', children: '根据您的档案储量为您提供专业独立预存区域任您选择，提供多项关于档案存放的服务', className: 'title-h2' },
      {
        name: 'content',
        children: (
          <div>
            <Row justify="center" gutter={20}>
              <Col lg={6} md={24} className="content136-system-left" offset={5}>
                <div className="content136-text-wrapper">
                  <div className="content136-text-title1"><span className="content136-text-blue"></span>专业的档案库房</div>
                  <div className="content136-text-content">高标准专业化的库房建设是华杰能为您提供的最有利保障，独立门禁系统、24小时监控设备，低电压冷光源LED照明、以及防火、防潮、防尘、防鼠、防盗、防光、防虫、防水，消除您的担忧，让档案存储更加的安全放心。</div>
                </div>
              </Col>
              <Col lg={12} md={24} className="content136-system-right"><img src={rightimg} className="content136-right-img" alt="system" /></Col>
            </Row>
            <br />
            <br />
            <br />
            <br />
            <br />
            <Row justify="center" gutter={20}>
              <Col lg={12} md={24} className="content136-system-right"><img src={leftimg} className="content136-right-img" alt="system" /></Col>
              <Col lg={6} md={24} className="content136-system-left">
                <div className="content136-text-wrapper">
                  <div className="content136-text-title1"><span className="content136-text-blue"></span>专业的服务团队</div>
                  <div className="content136-text-content">通过培训，华杰已组建了一支专业档案整理、打包、运送、配送集各项档案资料储存运输服务于一身的专业团队，同时通过专业设备：RFID 电子标签、全国车载GPS车辆定位、电子标签采集器等一系列的安全配套工具设施设备一应俱全。</div>
                </div>
              </Col>
            </Row>
          </div>
        ),
        className: 'title-content',
      },
    ],
  },
};
export const Content138DataSource = {
  OverPack: {
    className: 'home-page-wrapper content138-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '档案存放环境', className: 'title-h1' },
      {
        name: 'content',
        children:
          '我们重视每一个对话者，确保客户合作关系的质量',
        className: 'title-content',
      },
      {
        name: 'content2',
        children: (<Sliderarrow2 />),
        className: 'title-content2',
      },
      {
        name: 'content3',
        children: ('文件存储中心'),
        className: 'content138-expalin',
      },
    ],
  },
};
export const Content135DataSource = {
  OverPack: {
    className: 'home-page-wrapper content135-wrapper k926lstj87j-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '服务优势', className: 'title-h1' },
      {
        name: 'content',
        children:
          '建立企业智慧人力资源',
        className: 'title-content',
      },
      {
        name: 'content2',
        children: (
          <div>
            <Row justify="center" gutter={[50, 60]}>
              <Col>
                <div className="content135-item-wrapper">
                  <div className="content135-news">
                    <div className="content135-news-title content135-bg1">降低成本、舒缓资金压力</div>
                    <div className="content135-news-text">降低资料遗失、被窃及损毁等风险及重建成本；降低资料遗失、被窃及损毁等风险及重建成本，简化办公室环境，节省储存架柜设备的投资。</div>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="content135-item-wrapper">
                  <div className="content135-news">
                    <div className="content135-news-title content135-bg2">精简人力，减少花费时间</div>
                    <div className="content135-news-text">精简人力，减少花费时间与人力成本去找寻所需资料及归档。可帮助员工专注在主要工作上，提升员工生产力及工作效率。随时依需要，提供存储中资料之相关完整的网络资讯报表，协助公司各部门资料掌控。</div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row justify="center" gutter={[50, 0]}>
              <Col>
                <div className="content135-item-wrapper">
                  <div className="content135-news">
                    <div className="content135-news-title content135-bg3">安全可靠，保护员工信息</div>
                    <div className="content135-news-text">维持资料机密性，由客户指定授权之特定员工才有密码上网查询、调阅权力；每箱/件资料进出都经条形码扫描登录文件保管中心。对每笔资料都可有精确管理记录，无论资料的动向还是在仓库内、运输中或在客户处，都可完全掌控。</div>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="content135-item-wrapper">
                  <div className="content135-news">
                    <div className="content135-news-title content135-bg4">档案一键查询、送达</div>
                    <div className="content135-news-text">可选择性及电脑索引编码，可避免资料遗失并可依各部门区分，分列成册；可网络连线即时做电脑资料查询、下单，以求实效；可做到快速的资料档案收送，只需要网络下单或一张传真资料，档案即可在要求的时间内送达。</div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        ),
        className: 'content135-news',
      },
    ],
  },
};
export const Content110DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper',
    playScale: 0.1,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '成为我们的合作伙伴', className: 'title-h1' },
    ],
  },
  button: {
    className: 'button-wrapper',
    children: {  className: 'button', children: (<Link to='/about/1' >联系我们</Link>) },
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 10,
        className: 'block',
        title: {
          className: 'logo',
          children: (
            <span>
              <img src={logo} alt="logo" className="footer1-logoimg" />
            </span>
          ),
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <div>
                  
                </div>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 8,
        className: 'block',
        title: { children: (<span class="footer1-hotline">咨询热线</span>) },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: '',
              children: (
                <span>
                  <p class="footer1-number">4000-960-970</p>
                </span>
              ),
            },
            {
              name: 'link1',
              href: '',
              children: (
                <span>
                  <p class="footer1-time">周一至周五9:00-18:00</p>
                </span>
              ),
            },
            {
              name: 'link3',
              href: '',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 4,
        className: 'block',
        title: { children: (<span className="footer1-wx">关注我们</span>) },
        childWrapper: {
          children: [
            {
              name: 'link0',
              children: (<img src={wxcode} alt="code" />),
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        山东华杰人力资源管理有限公司 版权所有
        <br />
        <a href="http://www.beian.miit.gov.cn/" target="_blank">备案号： 鲁ICP备11016485号</a>
      </span>
    ),
  },
};




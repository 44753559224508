import React from 'react';
import arrow from '../../img/home/arrow.png';
import hotpos from '../../img/home/hotpos.png';
import item from '../../img/home/item.png';
import { Divider,  } from 'antd';
import axios from 'axios';

export default class HotItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data1: [],
      data2: []
    }
  }

  componentDidMount() {
    axios.get(`/hr/api/v1/newsTypes/selectNewsType`, {
      params: {
        groupCode: 'recruitment',
        groupType: 3,
        pageNo: 1,
        pageSize: 3
      }
    })
    .then((response)=> {
      try {
        if (response.data.data.entities) {
          this.setState({
            data: response.data.data.entities,
          })
        }
      } catch (err) {
        console.log(err);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
    axios.get(`/hr/api/v1/newsTypes/selectNewsType`, {
      params: {
        groupCode: 'commodity',
        groupType: 4,
        pageNo: 1,
        pageSize: 3
      }
    })
    .then((response)=> {
      try {
        if (response.data.data.entities) {
          this.setState({
            data2: response.data.data.entities,
          })
        }
      } catch (err) {
        console.log(err);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  render() {
    return (
      <div>
        <div className="content7-right-item-wrapper">
          <span className="content7-right-item-title">考务报名</span>
          <a className="content7-right-item-test" href="https://qzpta7.chinasyks.org.cn:18443/cn_sdhuajie/index.html#/index" target="_blank" >
            <div className="content7-right-item-text"><span>考试报名系统</span></div>
          </a>
        </div>
        <Divider style={{margin: '10px 0'}}/>
        <div className="content7-right-item-wrapper">
          <div className="content7-right-item-title">
            <span className="content7-right-item-title">热门职位招聘</span>
            <span className="content7-right-item-more">更多></span>
          </div>
          <div className="content7-right-item-bottom">
            <img src={hotpos} alt="img" className="content7-right-item-img" />
            <div>
              {
                this.state.data1.map((item, index)=>{
                  return (
                    <div className="content7-right-item-items">
                      <img src={arrow} alt="img" className="content7-right-item-arrow"/>
                  <span className="content7-right-hotpos-text">{item.name}</span>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
        <Divider style={{margin: '10px 0'}}/>
        <div className="content7-right-item-wrapper">
          <div className="content7-right-item-title">
            <span className="content7-right-item-title">热门商品</span>
            <span className="content7-right-item-more">更多></span>
          </div>
          <div className="content7-right-item-bottom">
            <img src={item} alt="img" className="content7-right-item-img" />
            <div>
              {
                this.state.data2.map((item, index)=>{
                  return (
                    <div className="content7-right-item-items">
                      <img src={arrow} alt="img" className="content7-right-item-arrow"/>
                  <span className="content7-right-hotpos-text">{item.name}</span>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
  
}
import React from 'react';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { getChildrenToRender } from './utils';
import { Row, Col, Tabs, Pagination, Divider, Breadcrumb } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';


// const url = 'https://testhr.lanxiang.co';
// axios.defaults.baseURL = url;
const { TabPane } = Tabs;


const params = [
  'notice',
  'publish',
  'groupDynamics',
  'policy',
  'recruit',
  'dynamic',
];


const breaditem = [
  '通知公告',
  '结果公示',
  '集团动态',
  '政策资讯',
  '人才招聘',
  '行业动态',
];

class Content13 extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      content: '',
      menukey: 0,
      data: [],
      pageNo: [ 1,1,1,1,1,1 ],
      total: 0
    }
  }

  handleMenu = key => {
    axios.get(`/hr/api/v1/newsTypes/selectNewsType`, {
      params: {
        groupCode: params[key],
        groupType: (key === 2 || key === '2') ? 2 : 1,
        pageNo: this.state.pageNo[key],
        pageSize: 10
      }
    })
    .then((response)=> {
      try {
        if (response.data.data.entities) {
          this.setState({
            data: response.data.data.entities,
            total: response.data.data.entityCount,
            menukey: key
          })
        }
      } catch (err) {
        console.log(err);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  };

  pageChange = (pageNo, pageSize) => {
    const page = this.state.pageNo;
    page[this.state.menukey] = pageNo;
    axios.get(`/hr/api/v1/newsTypes/selectNewsType`, {
      params: {
        groupCode: params[this.state.menukey],
        groupType: (this.state.menukey === 2 || this.state.menukey === '2') ? 2 : 1,
        pageNo: pageNo,
        pageSize: 10
      }
    })
    .then((response)=> {
      try {
        if (response.data.data.entities) {
          this.setState({
            data: response.data.data.entities,
            total: response.data.data.entityCount,
            pageNo: page
          })
        }
      } catch (err) {
        console.log(err);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }


  componentDidMount() {
    let key = 0;
    if (this.props.menukey) {
      key = this.props.menukey;
    }
    axios.get(`/hr/api/v1/newsTypes/selectNewsType`, {
      params: {
        groupCode: params[key],
        groupType: (key === 2 || key === '2') ? 2 : 1,
        pageNo: this.state.pageNo[key],
        pageSize: 10,
      }
    })
    .then((response)=> {
      try {
        if (response.data.data.entities) {
          this.setState({
            data: response.data.data.entities,
            total: response.data.data.entityCount,
            menukey: key
          })
        }
      } catch (err) {
        console.log(err);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  render() {
    const { ...props } = this.props;
    const { dataSource, isMobile } = props;
    delete props.dataSource;
    delete props.isMobile;

    let news = [];
    if (this.state.data) {
      news = this.state.data.map((item, index)=> {
        return (
          <Link key={index} to={`/news/detail/${item.id}`}>
            <div className="content1313-item">
              <span className={isMobile ? 'content1313-item-title-mobile' : 'content1313-item-title'}>{item.name}</span>
              <span className={isMobile ? 'content1313-item-time content1313-none' : 'content1313-item-time'}>{moment(item.pushDate).format('YYYY-MM-DD')}</span>
              <Divider style={{margin: '10px 0 0 0'}}/>
            </div>
          </Link>
        )
      });
    }
    const pagination = 
      <Pagination
          size="small"
          total={this.state.total}
          showQuickJumper
          className={isMobile ? 'content1313-pagination-mobile' : 'content1313-pagination'}
          key="pagination"
          onChange={this.pageChange}
          current={this.state.pageNo[this.state.menukey]}
          showSizeChanger={false}
        />
    ;
    news.push(pagination);
    return (
      <OverPack {...props} {...dataSource.OverPack}>
        <QueueAnim
          type="bottom"
          leaveReverse
          key="page"
          delay={[0, 100]}
          {...dataSource.titleWrapper}
        > 
          <Row justify="center">
            <Col>
              <div className="content1313-title-h1">新闻资讯</div>
              <Tabs 
                activeKey={`${this.state.menukey ? this.state.menukey : 0}`}
                onChange={this.handleMenu} 
                tabPosition={isMobile ? 'top' : 'left'}
              >
                {
                  breaditem.map((item1, index1)=> {
                    return (
                      <TabPane tab={item1} key={index1}>
                        <div className="content1313-right">
                          <Breadcrumb separator=">" className="content1313-breadcrumb">
                            <Breadcrumb.Item>新闻资讯</Breadcrumb.Item>
                            <Breadcrumb.Item>{breaditem[this.state.menukey]}</Breadcrumb.Item>
                          </Breadcrumb>
                          <Divider style={{margin: '20px 0 0 0'}} />
                          <div>
                            {
                                news.map((item2, index2)=> {
                                  return item2;
                                }) 
                            }
                          </div>
                        </div>
                      </TabPane>
                    )
                  })
                }
              </Tabs>
            </Col>
          </Row>
          {dataSource.titleWrapper.children.map(getChildrenToRender)}
        </QueueAnim>
      </OverPack>
    );
  }
}

export default Content13;

import React from 'react';
import TweenOne from 'rc-tween-one';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { Row, Col, Divider } from 'antd';
import { getChildrenToRender } from './utils';
import { isImg } from './utils';
import { Link } from 'react-router-dom';


class Footer extends React.Component {
  static defaultProps = {
    className: 'footer1',
  };

  getLiChildren = (data) =>
    data.map((item, i) => {
      const { title, childWrapper, ...itemProps } = item;
      if (childWrapper.children[0].name === "content0") {
        childWrapper.children[0].children = 
          <div>
            <Divider orientation="left" style={{ color: '#CCCCCC', fontWeight: 'normal' }}></Divider>
            <Row gutter={50} justify="space-around">
              <Col md={8} xs={24}>
                <Row gutter={[0, 0]}>
                  <Col span={24} className={this.props.isMobile ? 'footer1-relavant footer1-relavant-center' : 'footer1-relavant'}>产品</Col>
                  <Col span={24} ><Link to="/product/humanresource"><div className={this.props.isMobile ? 'footer1-relavant-text footer1-text-center' : 'footer1-relavant-text'}>人力资源外包</div></Link></Col>
                  <Col span={24} ><Link to="/product/business"><div className={this.props.isMobile ? 'footer1-relavant-text footer1-text-center' : 'footer1-relavant-text'}>业务流程外包</div></Link></Col>
                  <Col span={24} ><Link to="/product/recruit"><div className={this.props.isMobile ? 'footer1-relavant-text footer1-text-center' : 'footer1-relavant-text'}>招聘流程外包</div></Link></Col>
                  <Col span={24} ><Link to="/product/labor"><div className={this.props.isMobile ? 'footer1-relavant-text footer1-text-center' : 'footer1-relavant-text'}>劳务派遣服务</div></Link></Col>
                  <Col span={24} ><Link to="/product/file"><div className={this.props.isMobile ? 'footer1-relavant-text footer1-text-center' : 'footer1-relavant-text'}>档案管理服务</div></Link></Col>
                </Row>
              </Col>
              <Col md={8} xs={24}>
                <Row gutter={[0, 0]}>
                  <Col span={24} className={this.props.isMobile ? 'footer1-relavant footer1-relavant-center' : 'footer1-relavant'}>新闻资讯</Col>
                  <Col span={24} ><Link to='/news'><div className={this.props.isMobile ? 'footer1-relavant-text footer1-text-center' : 'footer1-relavant-text'}>集团动态</div></Link></Col>
                  <Col span={24} ><Link to={{pathname: '/news',query: 1}}><div className={this.props.isMobile ? 'footer1-relavant-text footer1-text-center' : 'footer1-relavant-text'}>通知公告</div></Link></Col>
                  <Col span={24} ><Link to={{pathname: '/news',query: 2}}><div className={this.props.isMobile ? 'footer1-relavant-text footer1-text-center' : 'footer1-relavant-text'}>行业动态</div></Link></Col>
                  <Col span={24} ><Link to='/news'><div className={this.props.isMobile ? 'footer1-relavant-text footer1-text-center' : 'footer1-relavant-text'}>更多</div></Link></Col>
                </Row>
              </Col>
              <Col md={8} xs={24}>
                <Row gutter={[0, 0]}>
                  <Col span={24} className={this.props.isMobile ? 'footer1-relavant footer1-relavant-center' : 'footer1-relavant'}>关于我们</Col>
                  <Col span={24} ><Link to='/about/1'><div className={this.props.isMobile ? 'footer1-relavant-text footer1-text-center' : 'footer1-relavant-text'}>公司简介</div></Link></Col>
                  <Col span={24} ><Link to='/about/2'><div className={this.props.isMobile ? 'footer1-relavant-text footer1-text-center' : 'footer1-relavant-text'}>品牌荣誉</div></Link></Col>
                  <Col span={24} ><Link to='/about/3'><div className={this.props.isMobile ? 'footer1-relavant-text footer1-text-center' : 'footer1-relavant-text'}>商务合作</div></Link></Col>
                </Row>
              </Col>
            </Row>
          </div>
      }
      return (
        <Col key={i.toString()} {...itemProps} title={null} content={null}>
          <h2 {...title}>
            {typeof title.children === 'string' &&
            title.children.match(isImg) ? (
              <img src={title.children} width="100%" alt="img" />
            ) : (
              title.children
            )}
          </h2>
          <div {...childWrapper}>
              {childWrapper.children.map(getChildrenToRender)}
          </div>
          </Col>
        );
              
    });

  render() {
    const { ...props } = this.props;
    const { dataSource } = props;
    delete props.dataSource;
    delete props.isMobile;
    const childrenToRender = this.getLiChildren(dataSource.block.children);
    return (
      <div {...props} {...dataSource.wrapper}>
        <OverPack {...dataSource.OverPack}>
          <QueueAnim
            type="bottom"
            key="ul"
            leaveReverse
            component={Row}
            {...dataSource.block}
          >
            {childrenToRender}
          </QueueAnim>
          <TweenOne
            animation={{ y: '+=30', opacity: 0, type: 'from' }}
            key="copyright"
            {...dataSource.copyrightWrapper}
          >
            <div {...dataSource.copyrightPage}>
              <div {...dataSource.copyright}>
                {dataSource.copyright.children}
              </div>
            </div>
          </TweenOne>
        </OverPack>
      </div>
    );
  }
}

export default Footer;

import React from 'react';
import { Col, Row, Menu, Dropdown,  } from 'antd';
import { Link } from 'react-router-dom';

import logo from '../../../img/logo.png';
import wxcode from '../../../img/wxcode.png';
import quanrizhi from '../../../img/humanresource/quanrizhi.png';
import zhuanye from '../../../img/humanresource/zhuanyerencai.png';
import rencai from '../../../img/humanresource/xuqiubodong.png';
import duoyang from '../../../img/humanresource/duoyanghua.png';
import line from '../../../img/humanresource/line.png';
import baoxian from '../../../img/humanresource/baoxian.png';
import daili from '../../../img/humanresource/daili.png';
import gongzi from '../../../img/humanresource/gongzi.png';
import dangan from '../../../img/humanresource/dangan.png';
import renshi from '../../../img/humanresource/renshi.png';
import shangye from '../../../img/humanresource/shangye.png';
import yewu from '../../../img/humanresource/yewu.png';
import zhengjian from '../../../img/humanresource/zhengjian.png';

const menu = (
  <Menu>
    <Menu.Item key="0">
      <Link to='/product/humanresource' ><div className="nav2-specitem">人力资源外包</div></Link>
    </Menu.Item>
    <Menu.Item key="1">
      <Link to='/product/business'><div className="nav2-specitem">业务流程外包</div></Link>
    </Menu.Item>
    <Menu.Item key="2">
      <Link to='/product/recruit' ><div className="nav2-specitem">招聘流程外包</div></Link>
    </Menu.Item>
    <Menu.Item key="3">
      <Link to='/product/labor' ><div className="nav2-specitem">劳务派遣服务</div></Link>
    </Menu.Item>
    <Menu.Item key="4">
      <Link to='/product/file' ><div className="nav2-specitem">档案管理服务</div></Link>
    </Menu.Item>
  </Menu>
);


export const Nav20DataSource = {
  isScrollLink: true,
  wrapper: { className: 'header2 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header2-logo',
    children: <Link to='/'><img src={logo} width="100%" alt="img" /></Link>,
  },
  LinkMenu: {
    className: 'header2-menu',
    children: [
      {
        name: 'linkNav',
        to: '#',
        children: (<Link to='/' style={{color: "#fff"}}>首页</Link>),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71n8tfut',
        to: '当前页面 ID 地址，参考如上',
        children: (
          <Dropdown overlay={menu} trigger={['click']} placement="bottomCenter">
            <a className="nav2-productlink" onClick={e => e.preventDefault()} style={{color: "#fff"}}>
              产品介绍
            </a>
          </Dropdown>
        ),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71sot4p',
        to: '当前页面 ID 地址，参考如上',
        children: (<Link to='/news' style={{color: "#fff"}}>新闻资讯</Link>),
        className: 'menu-item',
      },
      {
        name: 'linkNav~k8y71sot4p',
        to: '当前页面 ID 地址，参考如上',
        children: (<Link to='/about/1' style={{color: "#fff"}}>关于我们</Link>),
        className: 'menu-item',
      },
    ],
  },
  mobileMenu: { className: 'header2-mobile-menu' },
};
export const Banner30DataSource = {
  wrapper: { className: 'banner30' },
  textWrapper: {
    className: 'banner30-text-wrapper',
    children: [
      {
        name: 'nameEn',
        className: 'banner30-name-en',
        children: '',
      },
      {
        name: 'slogan',
        className: 'banner30-slogan',
        children: '人力资源外包',
        texty: true,
      },
      {
        name: 'name',
        className: 'banner30-name',
        children: '打造数字化云上蓝图，建立企业智慧人力资源',
      },
      { name: 'button', className: 'banner30-button', children: (
        <Link to='/about/1' style={{color: "#fff"}}>联系我们</Link>
        )
      },
    ],
  },
};
export const Feature70DataSource = {
  wrapper: { className: 'home-page-wrapper feature70-wrapper' },
  page: { className: 'home-page feature70' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature70-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'feature70-title-h1',
        children: '服务内容',
      },
      {
        name: 'content',
        className: 'feature70-title-content',
        children: '实现人力资源管理效率最大化',
      },
    ],
  },
  blockWrapper: {
    className: 'feature70-block-wrapper',
    gutter: 24,
    children: [
      {
        md: 6,
        xs: 24,
        name: 'block0',
        className: 'feature70-block',
        children: {
          className: 'feature70-block-group',
          children: [
            {
              name: 'image',
              className: 'feature70-block-image',
              children:(<img src={baoxian} alt="img" />),
            },
            {
              name: 'title',
              className: 'feature70-block-title',
              children: '社会保险服务',
            },
            {
              name: 'content',
              className: 'feature70-block-content',
              children: '社保基数审核、计算、申报、缴纳、帐户合并与转移。',
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block1',
        className: 'feature70-block',
        children: {
          className: 'feature70-block-group',
          children: [
            {
              name: 'image',
              className: 'feature70-block-image',
              children: (<img src={shangye} alt="img" />),
            },
            {
              name: 'title',
              className: 'feature70-block-title',
              children: '商业保险方案设计和执行',
            },
            {
              name: 'content',
              className: 'feature70-block-content',
              children: '提供灵活的商业保险方案设计和执行',
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block2',
        className: 'feature70-block',
        children: {
          className: 'feature70-block-group',
          children: [
            {
              name: 'image',
              className: 'feature70-block-image',
              children:(<img src={gongzi} alt="img" />),
            },
            {
              name: 'title',
              className: 'feature70-block-title',
              children: '工资服务',
            },
            {
              name: 'content',
              className: 'feature70-block-content',
              children: '代发员工工资、代扣代缴个人所得税。',
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block3',
        className: 'feature70-block',
        children: {
          className: 'feature70-block-group',
          children: [
            {
              name: 'image',
              className: 'feature70-block-image',
              children:(<img src={dangan} alt="img" />),
            },
            {
              name: 'title',
              className: 'feature70-block-title',
              children: '员工档案服务',
            },
            {
              name: 'content',
              className: 'feature70-block-content',
              children: '为员工提供统一的安全可靠的存档服务。',
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block3',
        className: 'feature70-block',
        children: {
          className: 'feature70-block-group',
          children: [
            {
              name: 'image',
              className: 'feature70-block-image',
              children:(<img src={renshi} alt="img" />),
            },
            {
              name: 'title',
              className: 'feature70-block-title',
              children: '人事手续服务',
            },
            {
              name: 'content',
              className: 'feature70-block-content',
              children: '依据档案出具各类人事证明、用/退工手续办理、集体户籍管理、员工档案身份确认等',
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block3',
        className: 'feature70-block',
        children: {
          className: 'feature70-block-group',
          children: [
            {
              name: 'image',
              className: 'feature70-block-image',
              children:(<img src={zhengjian} alt="img" />),
            },
            {
              name: 'title',
              className: 'feature70-block-title',
              children: '各种证件服务',
            },
            {
              name: 'content',
              className: 'feature70-block-content',
              children: '为符合条件的员工办理工作居住证、办理出国护照、港澳通行证等。',
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block3',
        className: 'feature70-block',
        children: {
          className: 'feature70-block-group',
          children: [
            {
              name: 'image',
              className: 'feature70-block-image',
              children:(<img src={yewu} alt="img" />),
            },
            {
              name: 'title',
              className: 'feature70-block-title',
              children: '业务政策咨询',
            },
            {
              name: 'content',
              className: 'feature70-block-content',
              children: '为符合条件的员工办理工作居住证、办理出国护照、港澳通行证等。',
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block3',
        className: 'feature70-block',
        children: {
          className: 'feature70-block-group',
          children: [
            {
              name: 'image',
              className: 'feature70-block-image',
              children:(<img src={daili} alt="img" />),
            },
            {
              name: 'title',
              className: 'feature70-block-title',
              children: '代理招聘服务',
            },
            {
              name: 'content',
              className: 'feature70-block-content',
              children: '根据不同企业的具体需要提供高级人才猎头服务及中低端人才招聘服务。',
            },
          ],
        },
      },
    ],
  },
};
export const Content1314DataSource = {
  OverPack: {
    className: 'home-page-wrapper content1314-wrapper k926lstj87j-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '', className: 'title-h1' },
      {
        name: 'content',
        children:(
          <Row justify="center">
            <Col>打造数字化云上蓝图，建立企业智慧人力资源</Col>
            <Link to="/about/1" style={{color: '#fff'}}><Col className="content1314-about">联系我们</Col></Link>
          </Row>
        ),
        className: 'title-content',
      },
    ],
  },
};
export const Content112DataSource = {
  OverPack: {
    className: 'home-page-wrapper content112-wrapper',
    playScale: 0.1,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', 
        children: '帮你解决多场景问题', 
        className: 'title-h1' 
      },
      { name: 'title', 
        children: (
          <div>
            <Row justify="center">
              <Col className="content112-bottom-wrapper">
                <img src={quanrizhi} alt="img" className="content112-bottom-img" />
                <div className="content112-bottom-text">全日制用工方式，成本高昂</div>
              </Col>
              <Col className="content112-bottom-wrapper">
                <img src={zhuanye} alt="img" className="content112-bottom-img" />
                <div className="content112-bottom-text">专业人才稀缺，招聘困难</div>
              </Col>
              <Col className="content112-bottom-wrapper">
                <img src={rencai} alt="img" className="content112-bottom-img" />
                <div className="content112-bottom-text">人员需求波动大，低需求期用工成本浪费</div>
              </Col>
              <Col className="content112-bottom-wrapper">
                <img src={duoyang} alt="img" className="content112-bottom-img" />
                <div className="content112-bottom-text">寻求多样化用工方式，提高内部人员效能</div>
              </Col>
            </Row>
            <img src={line} alt="img" className="content112-bottom-line" />
          </div>
        ), 
        className: 'content-wrapper' 
      },
    ],
  },
};
export const Content133DataSource = {
  OverPack: {
    className: 'home-page-wrapper content133-wrapper k926lstj87j-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '服务优势', className: 'title-h1' },
      {
        name: 'content',
        children:
          '建立企业智慧人力资源',
        className: 'title-content',
      },
      {
        name: 'content2',
        children: (
          <div>
            <Row justify="center" gutter={[50, 60]}>
              <Col>
                <div className="content133-item-wrapper">
                  <div className="content133-news">
                    <div className="content133-news-title content133-bg1">降低成本、舒缓资金压力</div>
                    <div className="content133-news-text">克服企业很多的规模经济弱点。在国内由于劳工权利意识的高涨、就业安全体系和劳动法令的普及，人事直接间接费用及外围成本不断地爬升，人力资源管理外包可以降低企业风险，摆脱杂务干扰，发展核心竞争优势。</div>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="content133-item-wrapper">
                  <div className="content133-news">
                    <div className="content133-news-title content133-bg2">简化流程，节省时间</div>
                    <div className="content133-news-text">避免大量投资于人才所带来的不确定风险，减少了企业人力资本投资的随机性，使组织更具灵活性，提高了企业对风险的应变能力。</div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row justify="center" gutter={[50, 0]}>
              <Col>
                <div className="content133-item-wrapper">
                  <div className="content133-news">
                    <div className="content133-news-title content133-bg3">操作规范，提高整体的管理水平</div>
                    <div className="content133-news-text">使企业能从复杂的人员管理中实现程序化操作，有效遏制随意性的薪资、员工关系管理，对管理工作的规范性、公正性起到促进作用。</div>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="content133-item-wrapper">
                  <div className="content133-news">
                    <div className="content133-news-title content133-bg4">提高企业的运转效率</div>
                    <div className="content133-news-text">现在企业招人难，用人难问题突出，自己人力有限，将人力资源外包的话，能大大提高企业的运转效率，而且可以降低企业的用工风险，助力企业发展。</div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        ),
        className: 'content133-news',
      },
    ],
  },
};
export const Content110DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper',
    playScale: 0.1,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '成为我们的合作伙伴', className: 'title-h1' },
    ],
  },
  button: {
    className: 'button-wrapper',
    children: {  className: 'button',  children: (<Link to='/about/1' >联系我们</Link>)  },
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 10,
        className: 'block',
        title: {
          className: 'logo',
          children: (
            <span>
              <img src={logo} alt="logo" className="footer1-logoimg" />
            </span>
          ),
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <div>
                  
                </div>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 8,
        className: 'block',
        title: { children: (<span class="footer1-hotline">咨询热线</span>) },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: '',
              children: (
                <span>
                  <p class="footer1-number">4000-960-970</p>
                </span>
              ),
            },
            {
              name: 'link1',
              href: '',
              children: (
                <span>
                  <p class="footer1-time">周一至周五9:00-18:00</p>
                </span>
              ),
            },
            {
              name: 'link3',
              href: '',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 4,
        className: 'block',
        title: { children: (<span className="footer1-wx">关注我们</span>) },
        childWrapper: {
          children: [
            {
              name: 'link0',
              children: (<img src={wxcode} alt="code" />),
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        山东华杰人力资源管理有限公司 版权所有
        <br />
        <a href="http://www.beian.miit.gov.cn/" target="_blank">备案号： 鲁ICP备11016485号</a>
      </span>
    ),
  },
};




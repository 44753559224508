import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Row, Col } from 'antd';
import QueueAnim from 'rc-queue-anim';
import Texty from 'rc-texty';
import 'rc-texty/assets/index.css';

class Banner extends React.PureComponent {
  render() {
    const { ...currentProps } = this.props;
    const { dataSource, isMobile } = currentProps;
    delete currentProps.dataSource;
    delete currentProps.isMobile;
    const children = dataSource.textWrapper.children.map((item) => {
      const { name, texty, ...$item } = item;
      if (name.match('button')) {
        return (
          <Link to="/about/1" key={name}>
            <div type="primary" {...$item}>
              {item.children}
            </div>
          </Link>
        );
      }

      return (
        <div key={name} {...$item}>
          {texty ? (
            <Texty type="mask-bottom">{item.children}</Texty>
          ) : (
            item.children
          )}
        </div>
      );
    });
    return (
      !isMobile ?

        <div {...currentProps} {...dataSource.wrapper}>
          <QueueAnim
            key="QueueAnim"
            type={['bottom', 'top']}
            delay={200}
            {...dataSource.textWrapper}
          >
            {children}
          </QueueAnim>
        </div>
          :
        <div>
          <div className="banner37-mobile-wrapper">
            <div className="banner35-mobile-slogan">丰富的人力资源管理经验</div>
            <div className="banner35-mobile-text">打造数字化云上蓝图，建立企业智慧人力资源</div>
            <div className="banner37-mobile-button"><Link to='/about/1' style={{color: "#fff"}} >了解详情</Link></div>
          </div>
          <div className="banner37-mobile-detail">
            <Row justify="center">
              <Col span={12}>
                <div className="banner37-item-mobile-wrapper">
                  <div className="banner37-item-mobile-title">人力系统</div>
                  <a className="banner37-item-mobile-enter" href="https://hr.csxr.cn/#/dashboard" target="_blank">点击进入 ></a>
                </div>
              </Col>
              <Col span={12}>
                <div className="banner37-item-mobile-wrapper">
                  <div className="banner37-item-mobile-title">招聘网站</div>
                  <div className="banner37-item-mobile-enter">点击进入 ></div>
                </div>
              </Col>
            </Row>
            <Row justify="center">
                    {/* <a className="banner37-item-mobile-enter" href="http://qzpta7.chinasyks.org.cn/cn_sdhuajie3/index.html#/index" target="_blank">点击进入 ></a> */}
                <Col span={12}>
                  <div className="banner37-item-mobile-wrapper">
                    <div className="banner37-item-mobile-title">考务系统</div>
                    {/* <a className="banner37-item-mobile-enter" href="http://qzpta7.chinasyks.org.cn/cn_sdyaojianju/index.html#/index" target="_blank">点击进入 ></a> */}
                    <a className="banner37-item-mobile-enter" target="_blank">点击进入 ></a>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="banner37-item-mobile-wrapper">
                    <div className="banner37-item-mobile-title">福利商城</div>
                    <div className="banner37-item-mobile-enter">点击进入 ></div>
                  </div>
                </Col>
            </Row>
          </div>
        </div>
    );
  }
}
export default Banner;
